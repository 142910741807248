import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './Header';


const Alerts = () => {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState({ task: '', date: '' });
  const [assignedTask, setAssignedTask] = useState({ nameFrom: '', nameTo: '', task: '', date: '', status: 'received' });
  const currentUser = 'John Doe'; // Replace with the actual current user's name

  // Fetch tasks assigned to the current user
  useEffect(() => {
    const fetchAssignedTasks = async () => {
      try {
        const response = await axios.get(`/api/tasks?assignedTo=${currentUser}`);
        setTasks(response.data);
      } catch (error) {
        console.error('Error fetching assigned tasks:', error);
      }
    };
    fetchAssignedTasks();
  }, [currentUser]);

  // Handle adding a task for yourself
  const handleAddTask = async () => {
    try {
      await axios.post('/api/tasks', { ...newTask, nameFrom: currentUser, nameTo: currentUser });
      setNewTask({ task: '', date: '' });
      // Fetch updated tasks after adding a new one
      const response = await axios.get(`/api/tasks?assignedTo=${currentUser}`);
      setTasks(response.data);
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };

  // Handle assigning a task to another user
  const handleAssignTask = async () => {
    try {
      await axios.post('/api/tasks', assignedTask);
      setAssignedTask({ nameFrom: '', nameTo: '', task: '', date: '', status: 'received' });
      // Optionally, you can fetch updated tasks here
    } catch (error) {
      console.error('Error assigning task:', error);
    }
  };

  // Handle updating task status
  const handleUpdateStatus = async (taskId, newStatus) => {
    try {
      await axios.put(`/api/tasks/${taskId}`, { status: newStatus });
      // Update the task status in the local state
      setTasks(tasks.map((task) => (task.id === taskId ? { ...task, status: newStatus } : task)));
    } catch (error) {
      console.error('Error updating task status:', error);
    }
  };

  return (
    <div>
        <Header />

      {/* 1. Assign tasks to yourself */}
      <div className='pt-20 pl-4'>
        <h2 className='font-bold text-blue-700 text-lg pb-2'>Your Recent Alerts</h2>
        <div className="px-4 py-6 bg-gray-50 rounded-lg shadow-sm mb-8">
        <div className="space-y-4">
          {[1, 2, 3].map((index) => (
            <div key={index} className="bg-white p-4 rounded-md shadow-sm border border-gray-200">
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm font-semibold text-gray-600">Alert {index}</span>
                <span className="text-xs text-gray-400">{index} hours ago</span>
              </div>
              <p className="text-gray-700">This is a placeholder for an alert message. Real data will appear here once available.</p>
              <div className="mt-2 flex justify-end">
                <span className="inline-block bg-blue-100 text-blue-800 text-xs px-2 py-1 rounded">New</span>
              </div>
            </div>
          ))}
        </div>
      </div>
        </div>

      {/* 2. Assign tasks to others */}
      <div className='pl-4'>
        <h2 className='font-bold text-blue-700 text-lg pt-6 pb-2'>Send Studio Blast</h2>
        <div className="relative w-3/4 mb-4">
              <select
                name="location"
                value={assignedTask.nameTo}
                onChange={(e) => setAssignedTask({ ...assignedTask, nameTo: e.target.value })}
                id="location"
                required
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              >
                <option value="" disabled hidden>Select a department</option>
                <option value="AD">Admin</option>
                <option value="BI">Billing</option>
                <option value="PC">Parent Coordination</option>
                <option value="PV">Providers</option>
                <option value="PR">PR</option>
                <option value="AL">All</option>
              </select>
              <label
                htmlFor="location"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                To Who
              </label>
            </div>
        <div class="relative w-3/4 mb-4">
          <input type='text' id="assign_name" placeholder=" " class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
          <label for="assign_name" class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Blast Name</label>
        </div>
        <div class="relative w-3/4 mb-4">
          <textarea id="assign_desc" placeholder="" value={assignedTask.task} onChange={(e) => setAssignedTask({ ...assignedTask, task: e.target.value })} class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
          <label for="assign_desc" class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Blast Description</label>
        </div>
        <div class="relative w-3/4 mb-4">
          <input type='date' value={assignedTask.date} onChange={(e) => setAssignedTask({ ...assignedTask, date: e.target.value })} id="assign_due" placeholder=" " class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
          <label for="assign_due" class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Due Date</label>
        </div>

        
        <button className="ml-1 border-2 p-2 border-blue-400 rounded-lg w-[10rem] bg-white" onClick={handleAssignTask}>Send Blast</button>
      </div>

      {/* 3. Display tasks assigned to you */}
      <div className='pl-4'>
        
        <ul>
          {tasks.map((task) => (
            <li key={task.id}>
              <p>From: {task.nameFrom}</p>
              <p>Task: {task.task}</p>
              <p>Date: {task.date}</p>
              <p>Status: {task.status}</p>
              <select
                value={task.status}
                onChange={(e) => handleUpdateStatus(task.id, e.target.value)}
              >
                <option value="received">Received</option>
                <option value="in-progress">In Progress</option>
                <option value="completed">Completed</option>
              </select>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Alerts;