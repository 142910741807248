import React from 'react';
import STInsuranceTable from './STInsuranceTable';

const STInsurance = () => {
  return (
    <div>
      <STInsuranceTable />
    </div>
  );
};

export default STInsurance;