import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
  

const OTPayInsuranceTab = () => {
    const { state } = useLocation();
    const { patient } = state;

    const [rowValues, setRowValues] = useState({
      '97110': null, // null, 'yes', or 'no'
      '97530': null,
    });
    
    const [firstName, setFirstName] = useState(patient.FirstName);
    const [lastName, setLastName] = useState(patient.LastName);
    const [insuranceCompany, setInsuranceCompany] = useState(patient.InsuranceCompany);

    const handleCheckboxChange = (rowId, value) => {
      setRowValues((prevState) => {
        const newState = { ...prevState };
        if (value === 'yes') {
          newState[rowId] = 'yes';
        } else if (value === 'no') {
          newState[rowId] = 'no';
        } else {
          newState[rowId] = null;
        }
        return newState;
      });
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
      
        // Prepare the data to send to the API
        const data = {
          firstName,
          lastName,         
          insuranceCompany,
        };
      
        try {
          // Send the data to the API Gateway endpoint
          const response = await fetch('/api/update-patient', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });
      
          // Handle the response from the API
          if (response.ok) {
            console.log('Patient data updated successfully');
          } else {
            console.error('Error updating patient data');
          }
        } catch (error) {
          console.error('Error:', error);
        }
    };

    return (
        <div className=''>
        <form>
        <div className="grid gap-6 mb-6 md:grid-cols-4">
            <div>
                <label for="child_first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Child First Name</label>
                <input type="text" id="child_first_name" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder='test' required/>
            </div>
            <div>
                <label for="child_last_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Child Last Name</label>
                <input type="text" id="child_last_name" value={lastName} onChange={(e) => setLastName(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="test" required />
            </div>
            <div>
                <label for="child_dob" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Child DOB</label>
                <input type="date" id="child_dob" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>  
            <div>
                <label for="insur" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Insurance</label>
                <input type="text" id="insur" value={insuranceCompany} onChange={(e) => setInsuranceCompany(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="123-45-678" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
            </div>
            <div>
                <label for="child_mem_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Child Member ID #</label>
                <input type="text" id="child_mem_id" value='' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>
            <div>
                <label for="insur_comp_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Insurance Company #</label>
                <input type="text" id="insur_comp_id" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>
            <div>
                <label for="sub_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Subscriber Name</label>
                <input type="text" id="sub_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>
            <div>
                <label for="sub_dob" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Subscriber DOB</label>
                <input type="text" id="sub_dob" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>
            <div>
                <label for="relation" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Relationship to Dependent</label>
                <div className="">
                        <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="relation">
                        <option>Mother</option>
                        <option>Father</option>
                        <option>Grandmother</option>
                        <option>Grandfather</option>
                        <option>Sister</option>
                        <option>Brother</option>
                        <option>Aunt</option>
                        <option>Uncle</option>
                        <option>Step-parent</option>
                        <option>Other</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                </div>
            </div>
            <div>
                <label for="sub_mem_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Subscriber Member ID #</label>
                <input type="text" id="sub_mem_id" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>
        </div>
        <hr className="my-12 h-0.5 border-t-0 bg-gray-700"/>
        <div className="grid gap-6 mb-6 md:grid-cols-3">
            <div>
                <label for="st_sess" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black"><b className="text-red-500">OT</b> Sessions</label>
                <input type="number" id="st_sess" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div>
            <div>
              <form className="mx-auto">
                  <label for="deduc_amt" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Deductible Amount</label>
                  <div className="">
                      <div className=" inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                      <svg className="-mb-10 w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 17.345a4.76 4.76 0 0 0 2.558 1.618c2.274.589 4.512-.446 4.999-2.31.487-1.866-1.273-3.9-3.546-4.49-2.273-.59-4.034-2.623-3.547-4.488.486-1.865 2.724-2.899 4.998-2.31.982.236 1.87.793 2.538 1.592m-3.879 12.171V21m0-18v2.2"/>
                      </svg>

                      </div>
                      <input type="number" id="deduc_amt" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="500" pattern="^\d{5}(-\d{4})?$" required />
                  </div>
                  <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">Please select a dollar amount..</p>
              </form>
            </div>
            <div>
                <label for="deduc_type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Deductible Type</label>
                <div className="">
                        <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="grid-state">
                        <option>Combined</option>
                        <option>Individual</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                </div>
            </div>
            <div className="">
              <form className="mx-auto">
                  <label for="charge_till_deduc" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Charge Per Session Until Deductible Met</label>
                  <div className="">
                      <div className=" inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                      <svg className="-mb-10 w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 17.345a4.76 4.76 0 0 0 2.558 1.618c2.274.589 4.512-.446 4.999-2.31.487-1.866-1.273-3.9-3.546-4.49-2.273-.59-4.034-2.623-3.547-4.488.486-1.865 2.724-2.899 4.998-2.31.982.236 1.87.793 2.538 1.592m-3.879 12.171V21m0-18v2.2"/>
                      </svg>

                      </div>
                      <input type="number" id="charge_till_deduc" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="65" pattern="^\d{5}(-\d{4})?$" required />
                  </div>
                  <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">Please select a dollar amount..</p>
              </form>
            </div>
            <div>
              <form className="mx-auto">
                  <label for="copay_amt" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Copay Amount</label>
                  <div className="">
                      <div className=" inset-y-0 start-0 top-0 flex items-center ps-3.5 pointer-events-none">
                      <svg className="-mb-10 w-4 h-4 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 17.345a4.76 4.76 0 0 0 2.558 1.618c2.274.589 4.512-.446 4.999-2.31.487-1.866-1.273-3.9-3.546-4.49-2.273-.59-4.034-2.623-3.547-4.488.486-1.865 2.724-2.899 4.998-2.31.982.236 1.87.793 2.538 1.592m-3.879 12.171V21m0-18v2.2"/>
                      </svg>

                      </div>
                      <input type="number" id="copay_amt" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="10" pattern="^\d{5}(-\d{4})?$" required />
                  </div>
                  <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">Please select a dollar amount..</p>
              </form>
            </div>
            <div>
                <label for="auth_req" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Authorization Required</label>
                <div className="">
                        <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="grid-state">
                        <option>Yes</option>
                        <option>No</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                </div>
            </div>
            <div>
                <label for="auth_num" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Authorization #</label>
                <input type="text" id="auth_num" value='' className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="123-456-7890" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
            </div>
            <div>
                <label for="hard_max" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Hard Max</label>
                <div className="">
                        <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="grid-state">
                        <option>Yes</option>
                        <option>No</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                </div>
            </div>
            <div>
                <label for="checks_to" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Checks to Member</label>
                <div className="">
                        <select className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="relation">
                        <option>No</option>
                        <option>Yes</option>
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                </div>
            </div>
        </div>
        <div>
            <label for="message" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Notes</label>
            <textarea id="message" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Answer here if applicable..."></textarea>
        </div>
        <hr className="mt-12 h-0.5 border-t-0 bg-gray-700"/>
        <div className="grid gap-6 mb-6 md:grid-cols-1">
        <div className="mt-6 mb-6">
                <label for="effect_date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Policy Effective Date</label>
                <input type="date" id="effect_date" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div> 
            <div className="mb-6">
                <label for="terminate_date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Policy Termination Date</label>
                <input type="date" id="terminate_date" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div> 
            <div className="mb-6">
                <label for="renew_date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">When Does Deductible Renew</label>
                <input type="date" id="renew_date" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
            </div> 
  
        </div>
        <hr className="mb-6 h-0.5 border-t-0 bg-gray-700"/>
        
        <div className="overflow-x-auto shadow-md sm:rounded-lg mt-8">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            OT
                        </th>
                        <th scope="col" className="px-6 py-3">
                            <div className="flex items-center">
                                Yes
                                <a href="#"><svg className="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
            <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z"/>
          </svg></a>
                            </div>
                        </th>
                        <th scope="col" className="px-6 py-3">
                            <div className="flex items-center">
                                No
                                <a href="#"><svg className="w-3 h-3 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
            <path d="M8.574 11.024h6.852a2.075 2.075 0 0 0 1.847-1.086 1.9 1.9 0 0 0-.11-1.986L13.736 2.9a2.122 2.122 0 0 0-3.472 0L6.837 7.952a1.9 1.9 0 0 0-.11 1.986 2.074 2.074 0 0 0 1.847 1.086Zm6.852 1.952H8.574a2.072 2.072 0 0 0-1.847 1.087 1.9 1.9 0 0 0 .11 1.985l3.426 5.05a2.123 2.123 0 0 0 3.472 0l3.427-5.05a1.9 1.9 0 0 0 .11-1.985 2.074 2.074 0 0 0-1.846-1.087Z"/>
          </svg></a>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      97110
                    </th>
                    <td className="px-6 py-4">
                      <input
                        type="checkbox"
                        checked={rowValues['97110'] === 'yes'}
                        onChange={() => handleCheckboxChange('97110', 'yes')}
                      />
                    </td>
                    <td className="px-6 py-4">
                      <input
                        type="checkbox"
                        checked={rowValues['97110'] === 'no'}
                        onChange={() => handleCheckboxChange('97110', 'no')}
                      />
                    </td>
                  </tr>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      97530
                    </th>
                    <td className="px-6 py-4">
                      <input
                        type="checkbox"
                        checked={rowValues['97530'] === 'yes'}
                        onChange={() => handleCheckboxChange('97530', 'yes')}
                      />
                    </td>
                    <td className="px-6 py-4">
                      <input
                        type="checkbox"
                        checked={rowValues['97530'] === 'no'}
                        onChange={() => handleCheckboxChange('97530', 'no')}
                      />
                    </td>
                  </tr>
                </tbody>
            </table>
        </div>

        <form onSubmit={handleSubmit}>
        <button type="submit" className="mt-6 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
        </form>
        </form>
    </div>
    );
};

export default OTPayInsuranceTab;