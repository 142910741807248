import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'; 
import ConfirmAppointmentModal from './ConfirmAppointmentModal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarDaysIcon, ClockIcon, MapPinIcon, UserIcon, CalendarIcon } from '@heroicons/react/24/outline';

function FindSlotModal({ isOpen, onClose }) {
    const [selectedProvID, setSelectedProvId] = useState(null);
    const initialFormState = {
        provider_id: '',
        location: '',
        service: '',
        start_time: '',
        end_time: '',
        end_date: '',
        repeat_interval: '',
    };
    const [formData, setFormData] = useState(initialFormState);
    const [provider, setProvider] = useState('');
    const [getProviders, setGetProviders] = useState([]);
    const [results, setResults] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const handleSlotSelect = (slot) => {
      const enhancedSlot = {
        ...slot,
        providerName: getProvName(slot.pr_id),
        providerId: slot.pr_id
      }
      console.log('Enhanced Slot:', enhancedSlot);
      setSelectedSlot(enhancedSlot);
      setShowConfirmModal(true);
    };

    const resetForm = () => {
        onClose();
        setFormData(initialFormState);
        setResults([]);
        setSelectedProvId(null);
        setProvider('');
    };

    const getProvName = (providerId) => {
        const provider = getProviders.find(p => p.id === providerId);
        return provider ? provider.name : 'Unknown Provider ID';
    };


    const autoProviders = async (e) => {
      if (e) e.preventDefault();
    
      try {
        const params = new URLSearchParams();
    
        const response = await axios.get(`https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_providers?${params.toString()}`);
    
        let providersArray = [];
    
        if (typeof response.data === 'string') {
          // Custom parsing for the specific string format
          const match = response.data.match(/body=(\[.*?\])/s);
          if (match && match[1]) {
            try {
              const bodyContent = match[1].replace(/'/g, '"'); // Replace single quotes with double quotes
              const parsedBody = JSON.parse(bodyContent);
              if (Array.isArray(parsedBody)) {
                providersArray = parsedBody.map(provider => ({
                  id: provider.pr_id,
                  name: provider.pr_name
                }));
              }
            } catch (parseError) {
              console.error('Error parsing body content:', parseError);
            }
          } else {
            console.error('Unable to extract body content from response');
          }
        } else if (typeof response.data === 'object' && response.data !== null) {
          // Handle case where response.data is already an object
          if (response.data.body && Array.isArray(response.data.body)) {
            providersArray = response.data.body.map(provider => provider.pr_name);
          } else {
            console.error('Unexpected data structure:', response.data);
          }
        } else {
          console.error('Unexpected response type:', typeof response.data);
        }
    
        setGetProviders(providersArray);
      } catch (error) {
        console.error('Error calling Lambda function:', error);
        setGetProviders([]);
      }
    };

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };

    const handleRepeatIntervalChange = (value) => {
      setFormData(prevData => ({
        ...prevData,
        repeat_interval: value
      }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const formatDateTime = (dateTimeString) => {
          const date = new Date(dateTimeString);
          return date.toISOString().slice(0, 19).replace('T', ' ');
        };
    
        const dataToSend = {
          ...formData,
          provider_id: parseInt(formData.provider_id),
          service: formData.service === "MS" ? null : formData.service,
          patient_id: parseInt(formData.patient_id),
          location: parseInt(formData.location),
          start_time: formatDateTime(formData.start_time),
          end_time: formatDateTime(formData.end_time),
          repeat_interval: parseInt(formData.repeat_interval)
        };

        console.log('Data Sent: ', dataToSend);
    
        try {
          const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/find_time_slot', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend)
          });
    
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const result = await response.json();
        if (result.body) {
        try {
            const parsedBody = JSON.parse(result.body);
            console.log('Success: ', result);
            setResults(parsedBody);
        } catch (error) {
            console.error('Error parsing response body:', error);
            setResults([]);
        }
        } else {
        console.error('Unexpected response structure:', result);
        setResults([]);
        }
        } catch (error) {
          console.error('Error:', error);
          // Handle error (e.g., show error message to user)
        }
    };


    useEffect(() => {
        autoProviders();
    }, []);

  if (!isOpen) return null;

  return (
    <>
      <div className="mt-8 justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative mx-auto w-full max-w-[800px] max-h-[90vh]">
          <div className="border-0 rounded-xl shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="sticky top-0 z-50 bg-white flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-2xl font-semibold">Find Appointment Slot</h3>
            </div>
            
            <div className="p-6 flex flex-col space-y-6">
              <div className="text-lg font-medium text-gray-700 -mb-4">
                Please fill in the details below to find available appointment slots.
              </div>
              <p className="mb-8 italic">Fields with <span class="text-red-600">*</span> indicate required inputs</p>

              <div className="grid grid-cols-2 gap-4">
                <div className="relative z-0 mt-4">
                  <select
                    name="provider_id"
                    value={provider}
                    onChange={(e) => {
                  const selectedProvider = getProviders.find(p => p.name === e.target.value);
                  setProvider(e.target.value);
                  const newProviderId = selectedProvider ? selectedProvider.id : null;
                  setSelectedProvId(newProviderId);

                  setFormData(prevState => ({
                    ...prevState,
                    provider_id: newProviderId,
                  }));
                }}
                disabled=''
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              >
                <option value="">None</option>
                {getProviders.length > 0 ? (
                    getProviders.map((provider) => (
                      <option key={provider.id} value={provider.name}>
                        {provider.name}
                      </option>
                    ))
                  ) : (
                    <option>No providers available</option>
                  )}
              </select>
              <label className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
                Provider
              </label>
            </div>
            <div className="relative z-0 mt-4">
              <select
                name="service"
                value={formData.service}
                onChange={handleChange}
                id="service"
                required
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              >
                <option value="" disabled hidden>Select a reason</option>
                <option value="ST">ST Session</option>
                <option value="OT">OT Session</option>
                <option value="MS">Both</option>
              </select>
              <label
                htmlFor="service"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Reason <span class="text-red-600">*</span>
              </label>
            </div>
            </div>
            <div className="relative z-0">
              <select
                name="location"
                value={formData.location}
                onChange={handleChange}
                id="location"
                required
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              >
                <option value="" disabled hidden>Select a location</option>
                <option value="">None</option>
                <option value="8">8</option>
                <option value="225">225</option>
                <option value="445">445</option>
              </select>
              <label
                htmlFor="location"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Location <span class="text-red-600">*</span>
              </label>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <div className="relative z-20">
              <DatePicker
                  name="start_time"
                  id="start_time"
                  selected={formData.start_time ? new Date(formData.start_time) : null}
                  onChange={(date) => handleChange({ target: { name: 'start_time', value: date } })}
                  showTimeSelect
                  timeIntervals={15}
                  timeFormat="hh:mm aa"
                  timeCaption="Time"
                  dateFormat="MMMM d, yyyy hh:mm aa"
                  className="block px-2.5 pb-2.5 pt-4 w-[23rem] text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer pr-10"
                  minTime={new Date(0, 0, 0, 8, 0)} // 8:00 AM
                  maxTime={new Date(0, 0, 0, 19, 30)} // 6:00 PM
                  placeholderText="Select date and time"
                  isClearable
                  popperPlacement="top"
                  popperClassName="z-30"
                  wrapperClassName="z-20"
                />
              <CalendarDaysIcon className={`absolute top-1/2 transform -translate-y-1/2 w-5 h-5 text-blue-600 transition-all duration-200 ${formData.start_time ? 'right-8' : 'right-3'}`} />
              <label 
                htmlFor="start_time" 
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Session Start Time <span class="text-red-600">*</span>
              </label>
            </div>

            <div className="relative z-20">
              <DatePicker
                  name="end_time"
                  id="end_time"
                  selected={formData.end_time ? new Date(formData.end_time) : null}
                  onChange={(date) => handleChange({ target: { name: 'end_time', value: date } })}
                  showTimeSelect
                  timeIntervals={15}
                  timeFormat="hh:mm aa"
                  timeCaption="Time"
                  dateFormat="MMMM d, yyyy hh:mm aa"
                  className="block px-2.5 pb-2.5 pt-4 w-[23rem] text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer pr-10"
                  minTime={new Date(0, 0, 0, 8, 0)} // 8:00 AM
                  maxTime={new Date(0, 0, 0, 19, 30)} // 6:00 PM
                  placeholderText="Select date and time"
                  isClearable
                  popperPlacement="top"
                  popperClassName="z-30"
                  wrapperClassName="z-20"
                />
              <CalendarDaysIcon className={`absolute top-1/2 transform -translate-y-1/2 w-5 h-5 text-blue-600 transition-all duration-200 ${formData.end_time ? 'right-8' : 'right-3'}`} />
              <label 
                htmlFor="end_time" 
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Session End Time <span class="text-red-600">*</span>
              </label>
            </div>
            </div>
            <div className="relative z-20">
            <DatePicker
              name="end_date"
              id="end_date"
              selected={formData.end_date ? new Date(formData.end_date) : null}
              onChange={(date) => handleChange({ target: { name: 'end_date', value: date } })}
              dateFormat="MMMM d, yyyy"
              className="block px-2.5 pb-2.5 pt-4 w-[47rem] text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer pr-10"
              placeholderText="Select date"
              isClearable
              popperPlacement="top"
              popperClassName="z-30"
              wrapperClassName="z-20"
            />
            <CalendarDaysIcon className={`absolute top-1/2 transform -translate-y-1/2 w-5 h-5 text-blue-600 transition-all duration-200 ${formData.end_date ? 'right-8' : 'right-3'}`} />
            <label 
              htmlFor="end_date" 
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
            >
              Last Appointment Recurrence <span class="text-red-600">*</span>
            </label>
          </div>

            {/* <div className="flex items-center space-x-4">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="weekly"
                    checked={formData.repeat_interval === 604800}
                    onChange={() => handleRepeatIntervalChange(604800)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label htmlFor="weekly" className="ml-2 text-sm font-medium text-gray-900">Weekly</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="none"
                    checked={formData.repeat_interval === null}
                    onChange={() => handleRepeatIntervalChange(null)}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  />
                  <label htmlFor="none" className="ml-2 text-sm font-medium text-gray-900">None</label>
                </div>
              </div> */}
            </div>
            <div className="sticky bottom-0 bg-white z-50 flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                    className="bg-white border-2 text-black active:bg-red-600 px-6 h-11 rounded-3xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={resetForm}
                >
                CANCEL
                </button>
                <button
                    className="bg-blue-500 border-2 text-white active:bg-blue-600 uppercase text-sm px-6 py-3 rounded-3xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="submit"
                    onClick={handleSubmit}
                >
                Find Slots
                </button>
            </div>

            {results && (
                <div className="mt-4 p-4 bg-gray-50 rounded-lg shadow-inner overflow-auto max-h-[60vh]">
                    <h2 className="text-xl font-bold mb-4 text-gray-800">Available Time Slots</h2>
                    {Array.isArray(results) && results.length > 0 ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                            {results.map((slot, index) => (
                                <div key={index} className="bg-white border border-gray-200 rounded-lg p-4 hover:shadow-md transition-shadow duration-300 cursor-pointer" onClick={() => handleSlotSelect(slot)}>
                                    <div className="flex flex-col space-y-2">
                                        <div className="flex items-center space-x-2">
                                            <UserIcon className="w-5 h-5 text-blue-500" />
                                            <span className="font-semibold">{getProvName(slot.pr_id)}</span>
                                            <span className="text-gray-500 text-xs">({slot.pr_id})</span>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <MapPinIcon className="w-5 h-5 text-green-500" />
                                            <span className="text-sm">Location: {slot.location}</span>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <CalendarIcon className="w-5 h-5 text-purple-500" />
                                            <span className="text-sm">Service: {slot.service}</span>
                                        </div>
                                        <div className="flex items-center space-x-2">
                                            <ClockIcon className="w-5 h-5 text-orange-500" />
                                            <span className="text-sm">
                                                {new Date(slot.start_time).toLocaleTimeString()} - {new Date(slot.end_time).toLocaleTimeString()}
                                            </span>
                                        </div>
                                    </div>
                                    <button 
                                        className="mt-3 w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition-colors duration-300 text-sm"
                                    >
                                        Select
                                    </button>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-gray-500 text-center py-4">No available time slots found.</p>
                    )}
                </div>
            )}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

      {showConfirmModal && (
        <ConfirmAppointmentModal
          isOpen={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          slot={selectedSlot}
          endDate={formData.end_date}
          onConfirm={() => {
            // Handle successful confirmation (e.g., refresh data, show message)
            setShowConfirmModal(false);
            // You might want to refresh the available slots here
          }}
        />
      )}
    </>
  );
}

export default FindSlotModal;