import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { PencilIcon, XMarkIcon, DocumentCheckIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import Header from './Header';
import { setDate } from 'date-fns';
import axios from 'axios';

const CreateNote = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { noteSection, patientIID, event, provider: passedProvider, eventName } = location.state || {};

  const [noteType, setNoteType] = useState('');
  const [noteContent, setNoteContent] = useState('');
  const [isEditing, setIsEditing] = useState(true);
  const patient = location.state?.patient;
  //const { noteSection } = location.state || {};
  const [dateService, setDateService] = useState(event?.start_time?.split('T')[0] || new Date().toISOString().split('T')[0]);
  const [provider, setProvider] = useState(passedProvider || '');
  const [selectedProvID, setSelectedProvId] = useState(null);
  const [getProviders, setGetProviders] = useState([]);


  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
  
    try {
      const params = new URLSearchParams();
      const response = await axios.get(`https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_providers?${params.toString()}`);
  
      let providersArray = [];
  
      if (typeof response.data === 'string') {
        // Custom parsing for the specific string format
        const match = response.data.match(/body=(\[.*?\])/s);
        if (match && match[1]) {
          try {
            const bodyContent = match[1].replace(/'/g, '"'); // Replace single quotes with double quotes
            const parsedBody = JSON.parse(bodyContent);
            if (Array.isArray(parsedBody)) {
              providersArray = parsedBody.map(provider => ({
                id: provider.pr_id,
                name: provider.pr_name
              }));
            }
          } catch (parseError) {
            console.error('Error parsing body content:', parseError);
          }
        } else {
          console.error('Unable to extract body content from response');
        }
      } else if (typeof response.data === 'object' && response.data !== null) {
        // Handle case where response.data is already an object
        if (response.data.body && Array.isArray(response.data.body)) {
          providersArray = response.data.body.map(provider => provider.pr_name);
        } else {
          console.error('Unexpected data structure:', response.data);
        }
      } else {
        console.error('Unexpected response type:', typeof response.data);
      }
  
      setGetProviders(providersArray);
    } catch (error) {
      console.error('Error calling Lambda function:', error);
      setGetProviders([]);
    }
  };

  const getNoteTypeOptions = () => {
    switch (noteSection) {
      case 'Daily Session':
        return ['Daily Session', 'ST Daily Session Note', 'OT Daily Session Note', 'Speech Therapy Intake', 'Occupational Therapy Intake'];
      case 'Peer Group':
        return ['Peer Group', 'Peer Group Notes'];
      case 'Treatment Plan':
        return ['Treatment Plan', 'ST Initial Treatment Plan', 'OT Initial Treatment Plan', 'ST Treatment Plan Update', 'OT Treatment Plan Update'];
      default:
        return ['Personal', 'Information From Caregiver', 'Assessment Notes', 'Misc.'];
    }
  };
  
  const noder = getNoteTypeOptions()[0];
  const noteTypeOptions = getNoteTypeOptions().slice(1);

  const onPencil = () => {
    setIsEditing(!isEditing);
  };

  const onDiscard = () => {
    // Placeholder function
    console.log('Discard note');
    navigate(`/patients/${patient.PatientID}`, {state: { patient } });
  };

  const onSaveClose = () => {
    // Placeholder function
    if (!noteType || !noteContent) {
        alert('Please fill in all required fields.');
        return;
    }
    addNote('In-Progress');
  };

  const onSaveSubmit = () => {
    // Placeholder function
    if (!noteType || !noteContent) {
        alert('Please fill in all required fields.');
        return;
    }
    addNote('Complete');
  };

  const addNote = async (status) => {
    let sentID;
    if (patientIID) {
      sentID = patientIID;
    } else if (patient.PatientID) {
      sentID = patient.PatientID;
    }
    try {
        const response = await axios.post('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/create_note', {
            note_type: noder, //daily, treatment, personal
            note_subtype: noteType,  //session, intake, etc.
            note_content: noteContent,
            note_status: status, //
            date_of_service: dateService,
            event_id: event.event_id,
            event_sch_id: event.event_schedule_id,
            created_by: selectedProvID,
            patient_id: sentID,
            created_at: new Date().toISOString().split('T')[0]
        });

        console.log('Note created:', response.data);
        navigate(`/patients/${patient?.PatientID || patientIID}`, {
          state: { patient: patient || { PatientID: patientIID } }
        });
    } catch (error) {
        console.error('Error creating note:', error);
    }
  };

  useEffect(() => {
    handleSubmit();

    if (eventName === "ST Appointment") {
      setNoteType("ST Daily Session Note");
    } else if (eventName === "OT Appointment") {
      setNoteType("OT Daily Session Note");
    }
    
    if (passedProvider) {
      setProvider(passedProvider);
      const providerObj = getProviders.find(p => p.name === passedProvider);
      if (providerObj) {
        setSelectedProvId(providerObj.id);
      }
    }
  }, [eventName, passedProvider, getProviders]);
  
  useEffect(() => {
    if (patientIID) {
      console.log("patientIID:", patientIID);
    } else {
      console.log("patient.PatientID : ", patient.PatientID);
    }
  }, []);

  return (
    <div>
        <Header />
    <div className="container mx-auto pt-24">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Create {noder} Note</h1>
        
        <button onClick={onPencil} className="p-2 rounded-full hover:bg-gray-200">
          <PencilIcon className="h-6 w-6 text-gray-600" />
        </button>
      </div>
      <div className="flex justify-between">
        <div className="relative w-5/12 mb-4">
            <input
            type="date"
            value={dateService}
            onChange={(e) => setDateService(e.target.value)}
            disabled={!isEditing}
            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            />
            <label className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
            Date of Service
            </label>
        </div>
        <div className="relative w-5/12 mb-4">
            <select
            value={noteType}
            onChange={(e) => setNoteType(e.target.value)}
            disabled={!isEditing}
            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
            >
            <option value="" disabled hidden>Select note type</option>
            {noteTypeOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
            ))}
            </select>
            <label className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
            Note Type
            </label>
        </div>
        </div>
        <div className="relative w-full mb-4">
        <select 
            value={provider} 
            onChange={(e) => {
              const selectedProvider = getProviders.find(p => p.name === e.target.value);
              setProvider(e.target.value);
              const newProviderId = selectedProvider ? selectedProvider.id : null;
              setSelectedProvId(newProviderId);
            }}  
            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" 
            id="grid-state"
        >
            <option value="">Select provider</option>
            {getProviders.length > 0 ? (
            getProviders.map((provider) => (
                <option key={provider.id} value={provider.name}>
                {provider.name}
                </option>
            ))
            ) : (
            <option>No providers available</option>
            )}
        </select>
        <label className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
            Provider
        </label>
        </div>

      <div className="relative w-full mb-4">
        <textarea
          value={noteContent}
          onChange={(e) => setNoteContent(e.target.value)}
          disabled={!isEditing}
          className="block px-2.5 pb-2.5 pt-4 w-full h-[25rem] text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
          placeholder=""
        ></textarea>
        <label className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">
          Note Content
        </label>
      </div>

      <div className="flex justify-end space-x-4">
        <button onClick={onDiscard} className="flex items-center px-4 py-2 bg-white border-2 border-red-500 text-black rounded-md hover:bg-red-500 hover:text-white duration-300 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">
          <XMarkIcon className="h-5 w-5 mr-2" />
          Discard
        </button>
        <button onClick={onSaveClose} className="flex items-center px-4 py-2 bg-white text-black border-2 border-blue-500 rounded-md hover:bg-blue-500 hover:text-white duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
          <DocumentCheckIcon className="h-5 w-5 mr-2" />
          Save and Close
        </button>
        <button onClick={onSaveSubmit} className="flex items-center px-4 py-2 bg-white text-black border-2 border-green-500 rounded-md hover:bg-green-500 hover:text-white duration-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2">
          <PaperAirplaneIcon className="h-5 w-5 mr-2" />
          Submit
        </button>
      </div>
    </div>
    </div>
  );
};

export default CreateNote;