import React from 'react';
import OTInsuranceTable from './OTInsuranceTable';

const OTInsurance = () => {
  return (
    <div>
      <OTInsuranceTable />
    </div>
  );
};

export default OTInsurance;