import React, { useRef } from 'react';
import { Printer } from 'lucide-react';
import ReactDOM from 'react-dom/client';

// Printable content component
export const ScheduleTranscript = ({ data }) => {
  return (
    <div className=" min-h-screen bg-white text-nowrap">
      <div className="max-w-3xl mx-auto bg-gray-100 rounded-lg overflow-hidden">
        {/* Header */}
        <div className="p-6 border-b">
          {/* Top row with logo and contact info */}
          <div className="flex justify-between items-start mb-6">
            {/* Logo */}
            <div className="w-full">
              <img 
                src="/sensorylogoFresh.png" 
                alt="Company Logo"
                className="w-8/12 h-full"
              />
            </div>
            
            {/* Contact Information */}
            <div className=" mt-4 text-right flex flex-col items-end ">
              <p className="font-medium whitespace-nowrap text-sky-700">THE SENSORY STUDIO</p>
              <p className="text-gray-600 whitespace-nowrap text-sky-700">225 RICHMOND HILL RD.</p>
              <p className="text-gray-600 whitespace-nowrap text-sky-700">STATEN ISLAND, NY 10314</p>
              <p className="text-gray-600 whitespace-nowrap text-sky-700">(718)979-5678</p>
            </div>
          </div>
          
          {/* Headers below logo */}
          <div className="space-y-2 ml-6">
            <h1 className="text-xl font-semibold mb-4 text-indigo-900">
              SPEECH AND OCCUPATIONAL THERAPY
            </h1>
            <h2 className="text-3xl font-bold text-sky-500 drop-shadow-md">
              Fall Schedule Transcript
            </h2>
            <h2 className="text-lg font-bold text-black">
              {data?.patient}
            </h2>
          </div>
        </div>

        {/* Content */}
        <div className="p-6 space-y-6">
          {/* Metadata Section */}
          <div className="grid grid-cols-4 gap-0 border-2 border-gray-800 rounded-lg">
            <div className="text-center bg-blue-300 p-2 border border-gray-600">
              <p className="text-xl text-black">Service</p>
            </div>
            <div className="text-center bg-blue-300 p-2 border border-gray-600">
              <p className="text-xl text-black">Provider</p>
            </div>
            <div className="text-center bg-blue-300 p-2 border border-gray-600">
              <p className="text-xl text-black">Day(s)</p>
            </div>
            <div className="text-center bg-blue-300 p-2 border border-gray-600">
              <p className="text-xl text-black">Time</p>
            </div>
            <div className="text-center bg-gray-50 p-3 border border-gray-600">
              <p className="font-bold">{data?.service || 'N/A'}</p>
            </div>
            <div className="text-center bg-gray-50 p-3 border border-gray-600">
              <p className="font-medium">{data?.provider || 'N/A'}</p>
            </div>
            <div className="text-center bg-gray-50 p-3 border border-gray-600">
              <p className="font-medium">{data?.day || 'N/A'}</p>
            </div>
            <div className="text-center bg-gray-50 p-3 border border-gray-600">
              <p className="font-medium">{data?.time || 'N/A'}</p>
            </div>
          </div>

          {/* Dynamic Content */}
          <div className="border rounded-lg p-6">
            <div className="space-y-6">
              {/* Your content here */}
              <section>
                <div className="space-y-3">
                  <div>
                    <span className="pl-2 py-1 bg-yellow-200 rounded-l-lg text-gray-600">Location of Sessions: </span>
                    <span className="pr-2 py-1 bg-yellow-200 rounded-r-lg font-medium">{data?.location}</span>
                  </div>
                  <div>
                    <span className="pl-2 py-1 bg-yellow-200 rounded-l-lg text-gray-600">Start Date: </span>
                    <span className="pr-2 py-1 bg-yellow-200 rounded-r-lg font-medium">{data?.startingDate}</span>
                  </div>
                </div>
                <div className="mt-8 space-y-3">
                  <div>
                    <span className="pl-2 py-1 bg-green-300 rounded-l-lg text-gray-600">2024 Deductible: </span>
                    <span className="pr-2 py-1 bg-green-300 rounded-r-lg font-medium">{data?.deductible}</span>
                  </div>
                  <div>
                    <span className="pl-2 py-1 bg-green-300 rounded-l-lg text-gray-600">Copay for Speech Therapy after Deductible is Met: </span>
                    <span className="pr-2 py-1 bg-green-300 rounded-r-lg font-medium">{data?.copay}</span>
                  </div>
                  <div>
                    <span className="pl-2 py-1 bg-green-300 rounded-l-lg text-gray-600">Intake Fee: </span>
                    <span className="pr-2 py-1 bg-green-300 rounded-r-lg font-medium">{data?.intake}</span>
                  </div>
                </div>
              </section>
            </div>
          </div>

          {/* Long Message */}
          <div>
            <p className='text-wrap text-xs mb-4'>
              Your health insurance carrier may send checks and Explanation of Benefit forms (EOBs) directly to your home address, for the services provided by The
              Sensory Studio. The checks and EOBs are reimbursement for services that were rendered without prepayment. Please sign the back of each check and
              bring the checks along with all documentation referring to the claims to any of our offices as soon as possible.
            </p>
            <p className='text-wrap text-xs'>
              To be clear, these checks represent payment for the services rendered to your child by The Sensory Studio.
            </p>
            <p className='text-wrap text-xs mb-4'> 
              Please sign and submit with all accompanying paperwork promptly.
            </p>
            <p className='text-wrap text-xs'>
              Thank you for your cooperation.
            </p>
          </div>

          {/* Footer */}
          <div className='text-center pt-6'>
            <span className='italic px-2 py-1 rounded-l-lg rounded-r-lg text-lg font-bold bg-red-300'>$15.00 no show fee for any session not attended without notice</span>
            <p className='italic text-sm'>Reminder: Your deductible will renew annually based on your individual insurance plan.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

// Custom button component
const CustomButton = ({ children, onClick, className = '' }) => {
  return (
    <button
      onClick={onClick}
      className={`inline-flex items-center justify-center px-4 py-2 rounded-md text-sm font-medium transition-colors
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
        ${className}`}
    >
      {children}
    </button>
  );
};

// Print button component
export const PrintButton = ({ documentData }) => {
  const printWindowRef = useRef(null);

  const handlePrint = () => {
    const printWindow = window.open('', '_blank', 'width=800,height=800');
    printWindowRef.current = printWindow;

    printWindow.document.title = documentData?.title || 'Document Title';

    // Updated styles with proper initialization and script loading
    const styles = `
      <!DOCTYPE html>
      <html>
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <script src="https://cdn.tailwindcss.com"></script>
          <style>
            /* Base styles */
            body {
              margin: 0;
              padding: 0;
              background-color: white;
            }

            /* Print-specific styles */
            @media print {
              .print-button { 
                display: none; 
              }
              @page {
                margin: 0;
                size: auto;
              }
              body {
                margin: 0;
                -webkit-print-color-adjust: exact !important;
                print-color-adjust: exact !important;
                forced-color-adjust: exact !important;
              }
              * {
                -webkit-print-color-adjust: exact !important;
                print-color-adjust: exact !important;
                forced-color-adjust: exact !important;
              }
            }

            /* Container styles */
            #print-container {
              width: 100%;
              min-height: 100vh;
            }
          </style>
        </head>
        <body>
          <div id="print-container"></div>
        </body>
      </html>
    `;

    // Write the complete HTML structure
    printWindow.document.write(styles);
    
    // Wait for Tailwind to be loaded
    printWindow.document.addEventListener('DOMContentLoaded', () => {
      const container = printWindow.document.getElementById('print-container');
      const root = ReactDOM.createRoot(container);
      
      root.render(
        <div className="w-full">
          <ScheduleTranscript data={documentData} />
          <div className="print-button fixed bottom-4 left-1/2 -translate-x-1/2">
            <CustomButton 
              onClick={() => {
                printWindow.print();
              }}
              className="bg-blue-500 hover:bg-blue-600 text-white"
            >
              Print Document
            </CustomButton>
          </div>
        </div>
      );
    });

    // Close the document writing
    printWindow.document.close();
  };

  return (
    <CustomButton
      onClick={handlePrint}
      className="bg-white hover:bg-gray-50 text-gray-700 border border-gray-300"
    >
      <Printer className="h-4 w-4 mr-2" />
      Print Document
    </CustomButton>
  );
};