import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UsersIcon, Square3Stack3DIcon, PlusIcon, DocumentChartBarIcon, Squares2X2Icon } from '@heroicons/react/24/outline';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { format } from 'date-fns';
const NotesTab = () => {
  const [activeTab, setActiveTab] = useState('Daily Session');
  const navigate = useNavigate();
  const { state } = useLocation();
  const { patient } = state;
  const [notes, setNotes] = useState([]);

  const tabs = [
    { name: 'Daily Session', icon: Squares2X2Icon },
    { name: 'Peer Group', icon: UsersIcon },
    { name: 'Treatment Plan', icon: DocumentChartBarIcon },
    { name: 'Personal', icon: Square3Stack3DIcon },
  ];

  useEffect(() => {
    fetchNotes();
  }, [activeTab, patient.PatientID]);

  const fetchNotes = async () => {
    try {
      console.log('active tab: ', activeTab);
      console.log('patient id: ', patient.PatientID);
      const response = await fetch(`https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_notes?patient_id=${patient.PatientID}&note_type=${activeTab}`);
      const data = await response.json();
      console.log('response success:', data);
      setNotes(JSON.parse(data.body));
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  };

  const getStatusStyle = (status) => {
    switch (status.toLowerCase()) {
      case 'complete':
        return 'bg-green-500 text-white';
      case 'in-progress':
        return 'bg-yellow-300 text-black';
      default:
        return 'bg-white text-black';
    }
  };

  const createNote = (noteSection) => {
    navigate(`/createnote`, { state: { noteSection, patient } });
  };

  const handleNoteClick = (note) => {
    navigate(`/viewnote/${note.id}`, {state: { note, patient } });
  };

  const renderNotes = () => {
    if (notes.length === 0) {
      return <p>No Notes Available</p>;
    }
  
    return notes.map(note => {
      const noteDate = new Date(note.date_service);
      return (
        <li key={note.id} className="flex items-start space-x-4 p-4 bg-stone-200 shadow-lg hover:bg-gray-200 rounded-lg transition duration-150 ease-in-out mb-4">
          <div className="flex-shrink-0 w-16 text-center">
            <p className="text-2xl font-semibold text-gray-900">{format(noteDate, 'dd')}</p>
            <p className="text-sm font-medium text-gray-500">{format(noteDate, 'MMM')}</p>
          </div>
          <div className="flex-grow">
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-lg font-semibold text-gray-900">{note.note_type} - {note.note_subtype}</h3>
              </div>
              <div className="flex items-center">
                <span className={`px-3 py-1 text-sm font-medium border-2 border-black rounded-full ${getStatusStyle(note.note_status)}`}>
                  {note.note_status}
                </span>
                <button onClick={() => handleNoteClick(note)} className="ml-2 px-3 py-1 text-sm font-medium border-2 border-black rounded-full bg-blue-500 text-white hover:bg-blue-600">
                  EDIT
                </button>
              </div>
            </div>
            <Disclosure>
              {({ open }) => (
                <>
                  <DisclosureButton className="mt-2 text-sm text-blue-600 hover:text-blue-800">
                    {open ? 'Hide Content' : 'Show Content'}
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 text-sm text-gray-700">
                    {note.note_content}
                  </DisclosurePanel>
                </>
              )}
            </Disclosure>
          </div>
        </li>
      );
    });
  };

  return (
    <div className="p-4">
      <div className='flex justify-between'>
        <div className="flex space-x-2 mb-4">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => setActiveTab(tab.name)}
              className={`flex items-center px-4 h-[2.5rem] rounded-md ${
                activeTab === tab.name
                  ? 'bg-blue-600 text-white'
                  : 'bg-blue-100 text-blue-700 hover:bg-blue-200'
              }`}
            >
              <tab.icon className="h-5 w-5 mr-2" />
              {tab.name}
            </button>
          ))}
        </div>
        <h1></h1>
        <div className='flex justify-between items-center mb-8'>
          <button 
            onClick={() => createNote(activeTab)} 
            className='flex items-center px-4 py-2 bg-neutral-500 text-white rounded-md hover:bg-neutral-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
          >
            <PlusIcon className='h-5 w-5 mr-2' />
            Create {activeTab} Note
          </button>
        </div>
      </div>
      <div className="bg-white rounded-md">
        {renderNotes()}
      </div>
    </div>
  );
};

export default NotesTab;