import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AddPatientPopUp = ({ openPopUp, closePopUp, fetchPatients, autoPatientID, patients }) => {
  const [newPatient, setNewPatient] = useState({
    PatientID: '',
    LastFirst: '',
    InactivePt: '',
    ST: '',
    OT: '',
    OT_Therapist: '',
    ST_Therapist: '',
    LastName: '',
    FirstName: '',
    PhoneNumber: '',
    Email: '',
    ParentGuardian: '',
    InsuranceCompany: '',
  });

  useEffect(() => {
    if (openPopUp) {
      const maxPatientID = getMaxPatientID(patients);
      const newPatientID = (maxPatientID + 1).toString();
      setNewPatient({ ...newPatient, PatientID: newPatientID });
    }
  }, [openPopUp, patients]);

  const getMaxPatientID = (patients) => {
    if (patients.length === 0) return 0;
    const patientIDs = patients.map((patient) => parseInt(patient.PatientID));
    return Math.max(...patientIDs);
  };

  const handleAddPatient = () => {
    const updatedNewPatient = {
      ...newPatient,
      PatientID: autoPatientID,
    };

    axios.post('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/addPatient', null, {
      headers: {
        "Content-Type": "application/json",
        ...updatedNewPatient
      },
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          alert('Patient Added Successfully!');
        } else {
          alert('Failed to add patient.');
        }
        setNewPatient({
          PatientID: '',
          LastFirst: '',
          InactivePt: '',
          ST: '',
          OT: '',
          OT_Therapist: '',
          ST_Therapist: '',
          LastName: '',
          FirstName: '',
          PhoneNumber: '',
          Email: '',
          ParentGuardian: '',
          InsuranceCompany: '',
        });
        closePopUp();
        fetchPatients();
      })
      .catch((error) => {
        console.error('Error adding patient:', error);
      });
  };

  const handleClosePopUp = (e) => {
    if (e.target.id === 'ModelContainer') {
      closePopUp();
    }
  };

  if (!openPopUp) return null;

  const inputFields = [
    { id: 'PatientID', label: 'Patient ID', disabled: true },
    { id: 'FirstName', label: 'First Name', placeholder: 'John' },
    { id: 'LastName', label: 'Last Name', placeholder: 'Doe' },
    { id: 'LastFirst', label: 'LastFirst', placeholder: 'DoeJohn', required: true },
    { 
      id: 'InactivePt', 
      label: 'Status', 
      type: 'select',
      options: ['New', 'Active', 'Inactive', 'Waiting List', 'Pending'],
      required: true 
    },
    { id: 'ParentGuardian', label: 'Primary Contact', placeholder: 'Jane Doe', required: true },
    { id: 'PhoneNumber', label: 'Phone Number', placeholder: '123-456-7890', type: 'tel', required: true },
    { id: 'Email', label: 'Email', placeholder: 'john.doe@gmail.com', required: true },
    { 
      id: 'ST', 
      label: 'ST', 
      type: 'select',
      options: ['ST', 'NA'],
      required: true 
    },
    { 
      id: 'OT', 
      label: 'OT', 
      type: 'select',
      options: ['OT', 'NA'],
      required: true 
    },
    { id: 'OT_Therapist', label: 'OT Therapist', required: true },
    { id: 'ST_Therapist', label: 'ST Therapist', required: true },
    { id: 'InsuranceCompany', label: 'Insurance Company', required: true },
  ];

  return (
    <div
      id="ModelContainer"
      onClick={handleClosePopUp}
      className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center backdrop-blur-sm"
    >
      <div className="bg-white w-11/12 md:w-2/3 lg:w-1/2 xl:w-1/3 rounded-lg shadow-lg overflow-hidden">
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-4 text-center">Add Patient</h2>
          <div className="max-h-[70vh] overflow-y-auto px-4">
            {inputFields.map((field) => (
              <div key={field.id} className="mb-4">
                <label htmlFor={field.id} className="block text-sm font-medium text-gray-700 mb-1">
                  {field.label}
                </label>
                {field.type === 'select' ? (
                  <select
                    id={field.id}
                    value={newPatient[field.id]}
                    onChange={(e) => setNewPatient({ ...newPatient, [field.id]: e.target.value })}
                    required={field.required}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  >
                    <option value="">Select {field.label}</option>
                    {field.options.map((option) => (
                      <option key={option} value={option}>{option}</option>
                    ))}
                  </select>
                ) : (
                  <input
                    type={field.type || 'text'}
                    id={field.id}
                    value={newPatient[field.id]}
                    onChange={(e) => setNewPatient({ ...newPatient, [field.id]: e.target.value })}
                    disabled={field.disabled}
                    placeholder={field.placeholder}
                    required={field.required}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                )}
              </div>
            ))}
          </div>
          <div className="mt-6 flex justify-end">
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
              onClick={handleAddPatient}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPatientPopUp;