import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Text } from 'recharts';

const COLORS = ['#4F46E5', '#7C3AED', '#EC4899'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value }) => {
  if (value === 0) return null;
  
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <Text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      style={{ fontSize: '12px' }}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </Text>
  );
};

const CustomLegend = ({ payload }) => {
  if (!payload || payload.length === 0) return null;

  return (
    <ul className="list-none p-0 mt-4 flex flex-col justify-center">
      {payload.map((entry, index) => (
        <li key={`item-${index}`} className="mb-1 flex items-center">
          <span
            className="w-3 h-3 rounded-full mr-2 inline-block"
            style={{ backgroundColor: entry.fill }}
          />
          <span className="text-sm text-gray-600">{entry.name}</span>
          <span className="ml-2 font-semibold">{`${((entry.value / payload.reduce((sum, item) => sum + item.value, 0)) * 100).toFixed(0)}%` + ` (${entry.value})`}</span>
          <span className="text-sm text-gray-600"></span>
        </li>
      ))}
    </ul>
  );
};

const CircularChart = ({ events }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const processEvents = () => {
      if (!events || events.length === 0) {
        return [
          { name: 'Appointments', value: 0, fill: COLORS[0] },
          { name: 'Meetings', value: 0, fill: COLORS[1] },
          { name: 'Other', value: 0, fill: COLORS[2] }
        ];
      }

      let appointments = 0;
      let meetings = 0;
      let other = 0;

      events.forEach(event => {
        const eventName = event.event_name.toLowerCase();
        if (eventName.includes('appointment')) {
          appointments++;
        } else if (eventName.includes('meeting')) {
          meetings++;
        } else {
          other++;
        }
      });

      return [
        { name: 'Appointments', value: appointments, fill: COLORS[0] },
        { name: 'Meetings', value: meetings, fill: COLORS[1] },
        { name: 'Other', value: other, fill: COLORS[2] }
      ];
    };

    setData(processEvents());
  }, [events]);

  const total = data.reduce((sum, item) => sum + item.value, 0);
  const overallPercentage = total > 0 ? Math.round((data[0].value / total) * 100) : 0;

  return (
    <div className="w-full flex flex-col items-center">
      <h2 className="text-xl font-bold mb-4 text-gray-800">How Your Day Is Looking</h2>
      <div className="w-full max-w-xs relative mb-4">
        <ResponsiveContainer width="100%" height={220}>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              innerRadius={50}
              fill="#8884d8"
              dataKey="value"
              startAngle={90}
              endAngle={-270}
              paddingAngle={0}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          <div className="text-2xl font-bold text-gray-800">{overallPercentage}%</div>
          <div className="text-xs text-gray-500">Overall</div>
        </div>
      </div>
      <CustomLegend payload={data} />
    </div>
  );
};

export default CircularChart;