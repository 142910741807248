/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from 'react';
import { format, addDays, subDays, addMonths, subMonths } from 'date-fns';
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, eachDayOfInterval, isSameDay, isSameMonth } from 'date-fns';
import Header from "./Header";
import { Link } from 'react-router-dom';
import { Fragment, useEffect, useRef } from 'react';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/solid';
import { Menu, MenuItem, MenuButton, MenuItems, Transition } from '@headlessui/react';
import CreateEventModal from './CreateEventModal';
import { Prev } from 'react-bootstrap/esm/PageItem';
import axios from 'axios';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example() {
  const container = useRef(null);
  const containerNav = useRef(null);
  const containerOffset = useRef(null);
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [providerName, setProviderName] = useState('');
  const [location, setLocation] = useState('');
  const [specialty, setSpecialty] = useState('');
  const [getProviders, setGetProviders] = useState([]);

  const [provider, setProvider] = useState('');
  const [events, setEvents] = useState([]);
  const [selectedProvID, setSelectedProvId] = useState(null);

  const [provider2, setProvider2] = useState('');
  const [events2, setEvents2] = useState([]);
  const [selectedProvID2, setSelectedProvId2] = useState(null);

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
  
    try {
      const params = new URLSearchParams();
      if (location) params.append('location', location);
      if (specialty) params.append('specialty', specialty);
      if (providerName) params.append('name', providerName + '%');
  
      const response = await axios.get(`https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_providers?${params.toString()}`);
  
      let providersArray = [];
  
      if (typeof response.data === 'string') {
        // Custom parsing for the specific string format
        const match = response.data.match(/body=(\[.*?\])/s);
        if (match && match[1]) {
          try {
            const bodyContent = match[1].replace(/'/g, '"'); // Replace single quotes with double quotes
            const parsedBody = JSON.parse(bodyContent);
            if (Array.isArray(parsedBody)) {
              providersArray = parsedBody.map(provider => ({
                id: provider.pr_id,
                name: provider.pr_name
              }));
            }
          } catch (parseError) {
            console.error('Error parsing body content:', parseError);
          }
        } else {
          console.error('Unable to extract body content from response');
        }
      } else if (typeof response.data === 'object' && response.data !== null) {
        // Handle case where response.data is already an object
        if (response.data.body && Array.isArray(response.data.body)) {
          providersArray = response.data.body.map(provider => provider.pr_name);
        } else {
          console.error('Unexpected data structure:', response.data);
        }
      } else {
        console.error('Unexpected response type:', typeof response.data);
      }
  
      setGetProviders(providersArray);
    } catch (error) {
      console.error('Error calling Lambda function:', error);
      setGetProviders([]);
    }
  };

  const updateCurrentDate = (date) => {
    setCurrentDate(date);
    setSelectedDate(date);
    handleProviderOrDateChange(selectedProvID, date);
  };


  const goForward = () => {
    const newDate = addDays(currentDate, 1);
    setCurrentDate(newDate);
    handleProviderOrDateChange(selectedProvID, newDate);
    //handleProviderOrDateChange2(selectedProvID, newDate);
  };

  const goToday = () => {
    const newDate = new Date();
    setCurrentDate(newDate);
    handleProviderOrDateChange(selectedProvID, newDate);
    //handleProviderOrDateChange2(selectedProvID, newDate);
  };

  const goBackward = () => {
    const newDate = subDays(currentDate, 1);
    setCurrentDate(newDate);
    handleProviderOrDateChange(selectedProvID, newDate);
    //handleProviderOrDateChange2(selectedProvID, newDate);
  };

  const handleProviderOrDateChange = (newProviderId, newDate) => {
    const formattedDate = format(newDate || currentDate, 'yyyy-MM-dd');
    const provId = newProviderId || selectedProvID;

    if (provId) {
      const payload = {
        provider_id: parseInt(provId),
        start_date: formattedDate,
        end_date: formattedDate
      };

      fetchEvents(payload);
    } else {
      setEvents([]);
    }
  };

  const handleProviderOrDateChange2 = (newProviderId, newDate) => {
    const formattedDate = format(newDate || currentDate, 'yyyy-MM-dd');
    const provId = newProviderId || selectedProvID2;

    if (provId) {
      const payload = {
        provider_id: parseInt(provId),
        start_date: formattedDate,
        end_date: formattedDate
      };

      fetchEvents2(payload);
    } else {
      setEvents2([]);
    }
  };

  const fetchEvents = async (payload) => {
    try {
      const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get-provider-events', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Lambda function response:', data);
      
      // Parse JSON string (data) into an array
      const parsedData = JSON.parse(data);
      console.log('Parsed data: ', parsedData);

      setEvents(parsedData);
    } catch (error) {
      console.error('Error calling Lambda function: ', error);
      setEvents([]);
    }
  };

  const fetchEvents2 = async (payload) => {
    try {
      const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get-provider-events', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Lambda function response:', data);
      
      // Parse JSON string (data) into an array
      const parsedData = JSON.parse(data);
      console.log('Parsed data: ', parsedData);

      setEvents2(parsedData);
    } catch (error) {
      console.error('Error calling Lambda function: ', error);
      setEvents2([]);
    }
  };


  useEffect(() => {
    handleSubmit();
    // Set the container scroll position based on the current time.
    const currentMinute = new Date().getHours() * 60;
    container.current.scrollTop =
      ((container.current.scrollHeight -
        containerNav.current.offsetHeight -
        containerOffset.current.offsetHeight) *
        currentMinute) /
      1440;
  }, []);

  return (
    <>
    <div className="z-30 sticky">
    <Header />
    </div>
    <div className="flex h-full flex-col">
      <div className="flex items-center justify-center pt-24 px-6">
        <div>
          <h1 className="text-lg font-semibold leading-6 text-gray-900">
            <time className="hidden sm:inline">
              {format(currentDate, 'MMMM d, yyyy')}
            </time>
          </h1>
          <p className="mt-1 text-sm text-gray-500">{format(currentDate, 'EEEE')}</p>
        </div>
        <div className="flex items-center">
          <div className="flex items-center rounded-md shadow-sm md:items-stretch ml-12">
            <button
              type="button"
              className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
              onClick={goBackward}
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <button
              type="button"
              onClick={goToday}
              className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
            >
              Today
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              type="button"
              onClick={goForward}
              className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden md:ml-4 md:flex md:items-center">
            <Menu as="div" className="relative">
              <MenuButton
                type="button"
                className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                Two Provider View
                <ChevronDownIcon
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </MenuButton>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <MenuItems className="focus:outline-none absolute right-0 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="py-1">
                    <MenuItem>
                        <Link to="/calendar" className='text-gray-700 block px-4 py-2 text-sm'>
                          Day View
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/weekview" className='text-gray-700 block px-4 py-2 text-sm'>
                          Week View
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/twoprovview" className='text-gray-700 block px-4 py-2 text-sm'>
                          Month View
                        </Link>
                    </MenuItem>
                    <MenuItem>
                        <Link to="/twoprovview" className='text-gray-700 block px-4 py-2 text-sm'>
                          Two Provider View
                        </Link>
                    </MenuItem>
                  </div>
                </MenuItems>
              </Transition>
            </Menu>
            <div className="ml-6 h-6 w-px bg-gray-300" />
            <button
              type="button"
              onClick = {() => setShowCreateEvent(true)}
              className="focus:outline-none ml-6 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Add Event
            </button>
            <CreateEventModal
              isOpen={showCreateEvent}
              onClose={() => setShowCreateEvent(false)}
            />
          </div>
          <Menu as="div" className=" ml-6 md:hidden">
            <MenuButton className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
              <span className="sr-only">Open menu</span>
              <Cog6ToothIcon className="h-5 w-5" aria-hidden="true" />
            </MenuButton>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <MenuItems className="focus:outline-none absolute right-0 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="py-1">
                  <MenuItem>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Create event
                      </a>
                    )}
                  </MenuItem>
                </div>
                <div className="py-1">
                  <MenuItem>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Go to today
                      </a>
                    )}
                  </MenuItem>
                </div>
                <div className="py-1">
                  <MenuItem>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Day view
                      </a>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Week view
                      </a>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Month view
                      </a>
                    )}
                  </MenuItem>
                  <MenuItem>
                    {({ active }) => (
                      <a
                        href="#"
                        className={classNames(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm'
                        )}
                      >
                        Year view
                      </a>
                    )}
                  </MenuItem>
                </div>
              </MenuItems>
            </Transition>
          </Menu>
        </div>
        </div>
        <div className="flex items-center justify-around py-6 px-6 border-b border-gray-200">
          <div className="flex items-center">
            <label htmlFor="provider" className="block mr-4 text-sm font-medium text-gray-900 dark:text-black">Provider #1</label>
            <div class="">
            <select 
              value={provider} 
              onChange={(e) => {
                const selectedProvider = getProviders.find(p => p.name === e.target.value);
                setProvider(e.target.value);
                const newProviderId = selectedProvider ? selectedProvider.id : null;
                setSelectedProvId(newProviderId);
                handleProviderOrDateChange(newProviderId, currentDate);
              }} 
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
              id="grid-state"
            >
              <option>None</option>
              {getProviders.length > 0 ? (
                getProviders.map((provider) => (
                  <option key={provider.id} value={provider.name}>
                    {provider.name}
                  </option>
                ))
              ) : (
                <option>No providers available</option>
              )}
            </select>
            </div>
          </div>
          <div className="flex items-center">
            <label htmlFor="provider" className="block mr-4 text-sm font-medium text-gray-900 dark:text-black">Provider #2</label>
            <div class="">
            <select 
              value={provider2} 
              onChange={(e) => {
                const selectedProvider = getProviders.find(p => p.name === e.target.value);
                setProvider2(e.target.value);
                const newProviderId = selectedProvider ? selectedProvider.id : null;
                setSelectedProvId2(newProviderId);
                handleProviderOrDateChange2(newProviderId, currentDate);
              }} 
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
              id="grid-state"
            >
              <option>None</option>
              {getProviders.length > 0 ? (
                getProviders.map((provider) => (
                  <option key={provider.id} value={provider.name}>
                    {provider.name}
                  </option>
                ))
              ) : (
                <option>No providers available</option>
              )}
            </select>
            </div>
          </div>
        </div>
        
        <div className="flex">
      <div className="flex flex-auto overflow-hidden bg-white">
        <div ref={container} className="flex flex-auto flex-col overflow-auto">
          <div
            ref={containerNav}
            className="sticky top-0 grid flex-none grid-cols-7 bg-white text-xs text-gray-500 shadow ring-1 ring-black ring-opacity-5 md:hidden"
          >
          </div>
          <div className="flex w-full flex-auto">
            <div className="w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Horizontal lines */}
              <div
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                style={{ 
                  gridTemplateRows: 'repeat(30, minmax(3.5rem, 1fr))' 
                }}
              >
                <div ref={containerOffset} className="row-end-1 h-7"></div>
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    6AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    7AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    8AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    9AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    10AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    11AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    12PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    1PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    2PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    3PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    4PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    5PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    6PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    7PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    8PM
                  </div>
                </div>
                <div />
              </div>

              {/* Events */}
              <ol
                className="col-start-1 col-end-2 row-start-1 grid grid-cols-1"
                style={{
                  gridTemplateRows: '1.75rem repeat(30, minmax(6rem, 1fr)) auto',
                }}
              >
                {events.map((event, index) => {
                  const startTime = new Date(event.start_time);
                  //console.log('StartTime: ', startTime);
                  const endTime = new Date(event.end_time);
                  //console.log('EndTime: ', endTime);
                  const startHour = startTime.getHours();
                  //console.log('StartHour: ', startHour);
                  const startMinutes = startTime.getMinutes();
                  //console.log('StartMinutes: ', startMinutes);
                  const duration = (endTime - startTime) / (1000 * 60);
                  //console.log('Duration: ', duration);

                  const gridRowStart = (startHour - 6) * 2 + Math.floor(startMinutes / 30) + 2;
                  //console.log('GridRowStart: ', gridRowStart);
                  const gridRowSpan = Math.ceil(duration / 30);
                  //console.log('GridRowSpan: ', gridRowSpan);

                  return (
                    <li
                      key={index}
                      className="relative mt-px flex"
                      style={{gridRow: `${gridRowStart} / span ${gridRowSpan}` }}
                    >
                      <a
                        href="#"
                        className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-blue-50 pl-2 text-xs leading-5 hover:bg-blue-100"
                      >
                        <p className="order-1 font-semibold text-blue-700">
                          Patient ID: {event.pt_id}
                        </p>
                        <p className="text-blue-500 group-hover:text-blue-700">
                          Event ID: {event.event_id}, Schedule ID: {event.event_schedule_id}
                        </p>
                        <p className="text-blue-500 group-hover:text-blue-700">
                          <time dateTime={startTime.toISOString()}>
                            {startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZone: 'America/New_York' })}
                          </time>
                          {' - '}
                          <time dateTime={endTime.toISOString()}>
                            {endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZone: 'America/New_York' })}
                          </time>
                        </p>
                      </a>
                    </li>
                  );
                })} 
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-auto overflow-hidden bg-white border-l border-blue-400">
        <div ref={container} className="flex flex-auto flex-col overflow-auto">
          <div
            ref={containerNav}
            className="sticky top-0 grid flex-none grid-cols-7 bg-white text-xs text-gray-500 shadow ring-1 ring-black ring-opacity-5 md:hidden"
          >
          </div>
          <div className="flex w-full flex-auto">
            <div className="w-14 flex-none bg-white ring-1 ring-gray-100" />
            <div className="grid flex-auto grid-cols-1 grid-rows-1">
              {/* Horizontal lines */}
              <div
                className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100"
                style={{ 
                  gridTemplateRows: 'repeat(30, minmax(3.5rem, 1fr))' 
                }}
              >
                <div ref={containerOffset} className="row-end-1 h-7"></div>
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    6AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    7AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    8AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    9AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    10AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    11AM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    12PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    1PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    2PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    3PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    4PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    5PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    6PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    7PM
                  </div>
                </div>
                <div />
                <div>
                  <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                    8PM
                  </div>
                </div>
                <div />
              </div>

              {/* Events */}
              <ol
                className="col-start-1 col-end-2 row-start-1 grid grid-cols-1"
                style={{
                  gridTemplateRows: '1.75rem repeat(30, minmax(6rem, 1fr)) auto',
                }}
              >
                {events2.map((event, index) => {
                  const startTime = new Date(event.start_time);
                  //console.log('StartTime: ', startTime);
                  const endTime = new Date(event.end_time);
                  //console.log('EndTime: ', endTime);
                  const startHour = startTime.getHours();
                  //console.log('StartHour: ', startHour);
                  const startMinutes = startTime.getMinutes();
                  //console.log('StartMinutes: ', startMinutes);
                  const duration = (endTime - startTime) / (1000 * 60);
                  //console.log('Duration: ', duration);

                  const gridRowStart = (startHour - 6) * 2 + Math.floor(startMinutes / 30) + 2;
                  //console.log('GridRowStart: ', gridRowStart);
                  const gridRowSpan = Math.ceil(duration / 30);
                  //console.log('GridRowSpan: ', gridRowSpan);

                  return (
                    <li
                      key={index}
                      className="relative mt-px flex"
                      style={{gridRow: `${gridRowStart} / span ${gridRowSpan}` }}
                    >
                      <a
                        href="#"
                        className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg bg-blue-50 pl-2 text-xs leading-5 hover:bg-blue-100"
                      >
                        <p className="order-1 font-semibold text-blue-700">
                          Patient ID: {event.pt_id}
                        </p>
                        <p className="text-blue-500 group-hover:text-blue-700">
                          Event ID: {event.event_id}, Schedule ID: {event.event_schedule_id}
                        </p>
                        <p className="text-blue-500 group-hover:text-blue-700">
                          <time dateTime={startTime.toISOString()}>
                            {startTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZone: 'America/New_York' })}
                          </time>
                          {' - '}
                          <time dateTime={endTime.toISOString()}>
                            {endTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZone: 'America/New_York' })}
                          </time>
                        </p>
                      </a>
                    </li>
                  );
                })} 
              </ol>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
    </>
  );
}
