import React, { useState, useEffect } from 'react';
import { format, parseISO, isValid } from 'date-fns';
import Header from './Header';
import { useNavigate } from 'react-router-dom';

// Moved EventsTable outside of ChargingTable
const EventsTable = ({ events, status }) => {
    const navigate = useNavigate();
    
    const handleButtonClick = (event) => {
        if (status === 'Confirmed') {
            navigate('/Charge', { 
                state: { 
                    eventData: event,
                    fromDate: status, 
                } 
            });
        } else {
            console.log('Alert Provider clicked for:', event);
        }
    };

    return (
        <div className="overflow-x-auto">
            <table className="w-full border-collapse">
                <thead>
                    <tr className="bg-gray-100">
                        <th className="p-4 text-left font-semibold text-gray-600">Provider</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Service</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Patient</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Date</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Time</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Status</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Amount</th>
                        <th className="p-4 text-left font-semibold text-gray-600">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {events.map((event, index) => {
                        const { date, time } = formatDateTime(
                            event.start_time,
                            event.end_time
                        );
                        
                        return (
                            <tr key={index} className="border-b border-gray-200 hover:bg-gray-50">
                                <td className="p-4">{event.pr_id}</td>
                                <td className="p-4">{getServiceName(event.event_name)}</td>
                                <td className="p-4">{event.patient_id}</td>
                                <td className="p-4">{date}</td>
                                <td className="p-4">{time}</td>
                                <td className="p-4">{status}</td>
                                <td className="p-4">$75</td>
                                <td className="p-4">
                                    <button
                                        onClick={() => handleButtonClick(event)}
                                        className={`px-4 py-2 rounded-lg text-white ${
                                            status === 'Confirmed'
                                                ? 'bg-green-500 hover:bg-green-600'
                                                : 'bg-yellow-500 hover:bg-yellow-600'
                                        }`}
                                    >
                                        {status === 'Confirmed' ? 'Charge' : 'Alert Provider'}
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

// Helper functions moved outside both components
const getServiceName = (evName) => {
    switch (evName) {
        case 'OT Appointment':
            return 'Occupational Therapy';
        case 'ST Appointment':
            return 'Speech Therapy';
        default:
            return 'N/A';
    }
};

const formatDateTime = (startTime, endTime) => {
    try {
        if (!startTime || !endTime) {
            return { date: 'N/A', time: 'N/A' };
        }
    
        const start = parseISO(startTime);
        const end = parseISO(endTime);
        
        if (!isValid(start) || !isValid(end)) {
            return { date: 'N/A', time: 'N/A' };
        }
    
        return {
            date: format(start, 'MMMM do'),
            time: `${format(start, 'h:mm')}-${format(end, 'h:mm')}`
        };
    } catch (error) {
        console.error('Error formatting date time:', error);
        return { date: 'N/A', time: 'N/A' };
    }
};

const ChargingTable = () => {
    const [activeTab, setActiveTab] = useState('confirmed');
    const [scheduledEvents, setScheduledEvents] = useState([]);
    const [confirmedEvents, setConfirmedEvents] = useState([]);
    const [noShowEvents, setNoShowEvents] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                const formattedDate = format(yesterday, 'yyyy-MM-dd');
        
                const requestBody = {
                    start_date: formattedDate,
                    end_date: formattedDate
                };
        
                const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_chargeable_events', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody)
                });
        
                const data = await response.json();
                const eventData = typeof data.body === 'string' ? JSON.parse(data.body) : data.body;
                
                setScheduledEvents(eventData.filter(event => event.ev_status === 'A'));
                setConfirmedEvents(eventData.filter(event => event.ev_status === 'Y'));
                setNoShowEvents(eventData.filter(event => event.ev_status === 'N'));
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };
    
        fetchEvents();
    }, []);

    return (
        <div>
            <Header />
            <div className="p-6 max-w-7xl mx-auto mt-12">
                <div className="mb-8">
                    <div className="border-b border-gray-200">
                        <nav className="flex -mb-px">
                            <button
                                onClick={() => setActiveTab('confirmed')}
                                className={`mr-8 py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'confirmed'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                {confirmedEvents.length} Confirmed
                            </button>
                            <button
                                onClick={() => setActiveTab('scheduled')}
                                className={`mr-8 py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'scheduled'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                {scheduledEvents.length} Scheduled
                            </button>
                            <button
                                onClick={() => setActiveTab('noshow')}
                                className={`py-4 px-1 border-b-2 font-medium text-sm ${
                                    activeTab === 'noshow'
                                        ? 'border-blue-500 text-blue-600'
                                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                }`}
                            >
                                {noShowEvents.length} No Show
                            </button>
                        </nav>
                    </div>
                </div>

                <div className="mt-6">
                    {activeTab === 'confirmed' && (
                        <EventsTable events={confirmedEvents} status="Confirmed" />
                    )}
                    {activeTab === 'scheduled' && (
                        <EventsTable events={scheduledEvents} status="Scheduled" />
                    )}
                    {activeTab === 'noshow' && (
                        <EventsTable events={noShowEvents} status="No Show" />
                    )}
                </div>
            </div>
        </div>
    );
};

export default ChargingTable;