import React, { useState } from 'react';
import { useLocation } from 'react-router-dom'; 

const PGInsurance = () => {
    const { state } = useLocation();
    const { patient } = state;
    const [billAs, setBillAs] = useState('');


    return (
        <div>
            <h1>PG Insurance Tab</h1>
            <div className="mb-6">
                <label for="intake_serv" className="block mb-2 text-sm font-medium text-gray-900 dark:text-black">Bill Peer Group As:</label>
                <div className="">
                    <select value={billAs} onChange={(e) => setBillAs(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" id="">
                        <option>N/A</option>
                        <option>Insurance (ST)</option>
                        <option>Out Of Pocket</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                </div>
            </div> 
        </div>
    );
};

export default PGInsurance;