import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';
import ProfileTable from './ProfileTable';
import { Menu, MenuButton, MenuItem, MenuItems, Transition, MenuSeparator } from '@headlessui/react';
import { ChevronDownIcon, PhotoIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
    return classes.filter(Boolean).join('')
}

const PatientProfile = () => {
  const { state } = useLocation();
  const { patient } = state;
  const [status, setStatus] = useState(patient.InactivePt);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageURL, setImageUrl] = useState(null);
  const [formData, setFormData] = useState({ photoConsent: patient.photoConsent || ''});
  const [showConfirmInactive, setShowConfirmInactive] = useState(false);
  const [inactiveReason, setInactiveReason] = useState('');

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleStatusChange = (newStatus) => {
    if (newStatus === 'Inactive') {
      setShowConfirmInactive(true);
    } else {
      setStatus(newStatus);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) return;

    const reader = new FileReader();
    reader.onloadend = async () => {
        const base64File = reader.result.split(',')[1]; //get base64 part
        try {
            const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/upload_profile_picture', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    patientID: patient.PatientID, 
                    fileName: selectedFile.name,
                    fileContent: base64File,
                }),
            });
            if (response.ok) {
                console.log('File uploaded successfully');
                const data = await response.json();
                setImageUrl(`https://sensorybucket.s3.amazonaws.com/${data.filePath}`);
            } else {
                console.error('Error uploading file');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
  
    let years = today.getFullYear() - birthDate.getFullYear();
    let months = today.getMonth() - birthDate.getMonth();
  
    if (months < 0) {
      years--;
      months += 12;
    }
  
    if (today.getDate() < birthDate.getDate()) {
      months--;
      if (months < 0) {
        years--;
        months += 12;
      }
    }
    
    return `${years} years and ${months} months`;
  };

  const handleConfirmInactive = () => {
    if (inactiveReason.trim() !== '') {
      setStatus('Inactive');
      // Here you would typically also save the reason to your backend
      console.log('Inactive reason:', inactiveReason);
      setShowConfirmInactive(false);
      setInactiveReason('');
    } else {
      alert('Please provide a reason for inactive status.');
    }
  };

  const handleCancelInactive = () => {
    setShowConfirmInactive(false);
    setInactiveReason('');
  };

  useEffect(() => {
    const fetchProfilePicture = async () => {
      if (patient && patient.PatientID) {
        try {
          const response = await fetch(`https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_profile_picture?patientID=${patient.PatientID}`);
          if (!response.ok) {
            throw new Error('Failed to fetch profile picture');
          }
          const data = await response.json();
          const parsedData = JSON.parse(data.body);
          setImageUrl(parsedData.imageUrl);
        } catch (err) {
          console.error(err.message);
        } 
      }
    };

    fetchProfilePicture();
  }, [patient]);


  const getButtonBgColor = () => {
    switch (status) {
        case 'Active':
            return 'bg-green-300';
        case 'Inactive':
            return 'bg-red-300';
        case 'New':
            return 'bg-blue-300';
        case 'Pending':
            return 'bg-yellow-300';
        case 'Waiting List':
            return 'bg-gray-300';
        default:
            return 'bg-white';
    }
  };

  if (!patient) {
    return <div>Loading...</div>
  }

  return (
    <div className="flex flex-col relative h-full">
      <Header />
      {/* Inactive Confirmation Popup */}
      {showConfirmInactive && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-md w-96">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">Confirm Inactive Status</h2>
            <p className="text-sm text-gray-600 mb-4">
              Please provide a reason for {patient.FirstName} {patient.LastName} becoming Inactive:
            </p>
            <textarea
              className="w-full p-2 border rounded-md mb-4"
              rows="3"
              value={inactiveReason}
              onChange={(e) => setInactiveReason(e.target.value)}
              placeholder="Enter reason here..."
            />
            <div className="flex justify-between">
              <button
                onClick={handleConfirmInactive}
                className="w-1/2 h-10 text-white font-semibold bg-red-600 hover:bg-red-700 rounded-md shadow-md transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-400 mr-2"
              >
                Confirm Inactive
              </button>
              <button
                onClick={handleCancelInactive}
                className="w-1/2 h-10 text-gray-700 font-semibold bg-gray-200 hover:bg-gray-300 rounded-md shadow-md transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-300 ml-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="pt-24 pb-8 flex justify-center items-center h-1/2">
        <div className="flex items-center space-x-8">
          <div className="">
          <img
              alt={`${patient.FirstName} ${patient.LastName}`}
              src={imageURL || "https://via.placeholder.com/150"}
              className="rounded-full h-32 w-32 object-cover"
            />
          </div>
          <div>
            <h1 className="text-4xl font-bold mb-2">
              {patient.FirstName} {patient.LastName} 
              <span className="pl-4 text-sm">
              ({patient.Services})
              </span>
            </h1>
            <div className="text-lg">
              {patient.DOB ? (
              <p>Age: {calculateAge(patient.DOB)}</p>
              ) : (
              <p>Age: N/A</p>
              )}
              <p>Gender: {patient.gender}</p>
            </div>
          </div>
        </div>
      </div>
      <form className="mb-4 flex justify-center" onSubmit={handleSubmit}>
        <input type='file' accept="image/png, image/jpeg" onChange={handleFileChange} className="mr-2" />
        <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">Upload Profile Picture</button>
      </form>
      {/*<form class="border-4 border-black" onSubmit={handleSubmit}>
        <input type='file' accept="image/png, image/jpeg" onChange={handleFileChange} />
        <button type="submit">Upload Profile Picture</button>
      </form>*/}
      <Menu as="div" className="inline-block text-left">
        <div className="flex justify-center">
          <MenuButton className={`inline-flex w-1/3 justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 ${getButtonBgColor()}`}>
            {status || 'Select Status'}
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-black" aria-hidden="true" />
          </MenuButton>
        </div>

        <Transition
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems className="absolute left-1/2 transform -translate-x-1/2 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1 flex flex-col">
              <MenuItem>
                {({ focus }) => (
                    <div className="">
                        <h1
                            onClick={() => handleStatusChange('Active')}
                            className={classNames(
                            focus ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm bg-gray-100 hover:bg-gray-300 hover:text-black',
                            )}
                        >
                            <span className="inline-flex items-center">
                            Active
                            <span className="inline-block ml-[41px]">
                            <svg width="20" height="20" viewBox="0 0 100 100">
                            <circle cx="50" cy="50" r="40" fill="green" />
                            </svg>
                            </span>
                            </span>
                        </h1>
                    </div>
                )}
              </MenuItem>
              <MenuSeparator className="my-1 h-px bg-black" />
              <MenuItem>
                {({ focus }) => (
                    <div className="">
                        <h1
                            onClick={() => handleStatusChange('Inactive')}
                            className={classNames(
                            focus ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm bg-gray-100 hover:bg-gray-300 hover:text-black',
                            )}
                        >
                            <span className="inline-flex items-center">
                            Inactive
                            <span className="inline-block ml-[31px]">
                            <svg width="20" height="20" viewBox="0 0 100 100">
                            <circle cx="50" cy="50" r="40" fill="red" />
                            </svg>
                            </span>
                            </span>
                        </h1>
                    </div>
                )}
              </MenuItem>
              <MenuSeparator className="my-1 h-px bg-black" />
              <MenuItem>
                {({ focus }) => (
                    <div className="">
                        <h1
                            onClick={() => handleStatusChange('New')}
                            className={classNames(
                            focus ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm bg-gray-100 hover:bg-gray-300 hover:text-black',
                            )}
                        >
                            <span className="inline-flex items-center">
                            New
                            <span className="inline-block ml-[52px]">
                            <svg width="20" height="20" viewBox="0 0 100 100">
                            <circle cx="50" cy="50" r="40" fill="blue" />
                            </svg>
                            </span>
                            </span>
                        </h1>
                    </div>
                )}
              </MenuItem>
              <MenuSeparator className="my-1 h-px bg-black" />
              <MenuItem>
                {({ focus }) => (
                    <div className="">
                        <h1
                            onClick={() => handleStatusChange('Pending')}
                            className={classNames(
                            focus ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm bg-gray-100 hover:bg-gray-300 hover:text-black',
                            )}
                        >
                            <span className="inline-flex items-center">
                            Pending
                            <span className="inline-block ml-7">
                            <svg width="20" height="20" viewBox="0 0 100 100">
                            <circle cx="50" cy="50" r="40" fill="yellow" />
                            </svg>
                            </span>
                            </span>
                        </h1>
                    </div>
                )}
              </MenuItem>
              <MenuSeparator className="my-1 h-px bg-black" />
              <MenuItem>
                {({ focus }) => (
                    <div className="">
                        <h1
                            onClick={() => handleStatusChange('Waiting List')}
                            className={classNames(
                            focus ? 'cursor-pointer bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm bg-gray-100 hover:bg-gray-300 hover:text-black',
                            )}
                        >
                            <span className="inline-flex items-center">
                            Waiting List
                            <span className="inline-block ml-2">
                            <svg width="20" height="20" viewBox="0 0 100 100">
                            <circle cx="50" cy="50" r="40" fill="gray" />
                            </svg>
                            </span>
                            </span>
                        </h1>
                    </div>
                )}
              </MenuItem>
            </div>
          </MenuItems>
        </Transition>
      </Menu>
      <div className="max-w-md mx-auto bg-white rounded-lg overflow-hidden mb-8">
        <div className="px-4 py-3 flex items-center justify-between">
          <div className="flex items-center">
            <div className="bg-gray-200 rounded-full p-2 mr-3">
              <PhotoIcon className="h-5 w-5 text-black"/>
            </div>
            <p className="font-semibold text-gray-800">Photo Consent</p>
          </div>
          <div className="ml-3 flex space-x-1">
            <button className="px-4 bg-white border-2 text-black rounded-xl hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 text-sm">
              No
            </button>
            <button className="px-4 bg-green-500 text-white rounded-xl hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 text-sm">
              Yes
            </button>
          </div>
        </div>
        <h1>{formData.photoConsent}</h1>
      </div>
      <ProfileTable />
    </div>
  );
};

export default PatientProfile;