import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import HomePage from './HomePage';
import { useUser } from './UserContext';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: '6e6sf8asn5r32osf519dgdjcst',
      userPoolId: 'us-east-1_ILWBE1quO',
      loginWith: { // Optional
        oauth: {
          domain: 'https://maxstudio.auth.us-east-1.amazoncognito.com',
          scopes: ['openid','email','phone','profile','aws.cognito.signin.user.admin'],
          redirectSignIn: ['https://maxstudiohealth.com/','https://maxstudiohealth.com/'],
          redirectSignOut: ['https://maxstudiohealth.com/','https://maxstudiohealth.com/'],
          responseType: 'code',
        },
        username: 'true',
        email: 'true', // Optional
        phone: 'false', // Optional
      }
    }
  }
});

async function fetchUserInfo(userId) {
  const apiUrl = 'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/find_login_user';
  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user_id: userId }),
    });
    const data = await response.json();
    console.log("Data received:", data);

    // Check if data.body is a string (JSON) or already an object
    const bodyData = typeof data.body === 'string' ? JSON.parse(data.body) : data.body;
    
    return bodyData;
  } catch (error) {
    console.error('Error fetching user info:', error);
    return null;
  }
}

export default function LoginPage() {
  const [showHomePage, setShowHomePage] = useState(false);
  const [userId, setUserId] = useState('');
  const { userName, userRole, setUserName, setUserRole } = useUser();
  const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHomePage(true);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (authStatus === 'authenticated' && user?.username) {
      setUserId(user.username);
    }
  }, [user, authStatus]);

  useEffect(() => {
    async function getUserInfo() {
      if (userId) {
        try {
          const userInfo = await fetchUserInfo(userId);
          console.log('User info received:', userInfo); // Add this line for debugging
          if (userInfo && userInfo.name) {
            setUserName(userInfo.name);
            setUserRole(userInfo.role || '');
          } else {
            console.error('Invalid user info received:', userInfo);
          }
        } catch (error) {
          console.error('Error getting user info:', error);
        }
      }
    }
  
    getUserInfo();
  }, [userId, setUserName, setUserRole]);

  return (
    <Authenticator>
      {({ signOut }) => (
        <main>
          {showHomePage ? (
            <HomePage signOut={signOut} />
          ) : (
            <>
              <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-300 to-blue-500">
                <div className="text-center">
                  <div className="mb-4">
                    <div className="w-16 h-16 border-4 border-white border-t-transparent rounded-full animate-spin mx-auto"></div>
                  </div>
                  <h1 className="text-2xl font-bold text-white mb-2">
                    Welcome {userName || 'Guest'}!
                  </h1>
                  <p className="text-white text-opacity-90 mb-4">
                    We're preparing your dashboard...
                  </p>
                  {userRole && (
                    <p className="text-white text-opacity-90 mb-4">
                      Role: {userRole}
                    </p>
                  )}
                  <button
                    onClick={signOut}
                    className="px-4 py-2 bg-white text-blue-600 rounded-md hover:bg-opacity-90 transition-colors"
                  >
                    Sign out
                  </button>
                </div>
              </div>
            </>
          )}
        </main>
      )}
    </Authenticator>
  );
}