import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Header from './Header';

// We'll use axios for API calls
import axios from 'axios';

//DropboxSign


// Basic component definitions (as in the previous version)
const Table = ({ children }) => <table className="min-w-full divide-y divide-gray-200">{children}</table>;
const Card = ({ children }) => <div className="bg-white shadow rounded-lg p-6">{children}</div>;
const CardHeader = ({ children }) => <div className="mb-4">{children}</div>;
const CardTitle = ({ children }) => <h2 className="text-xl font-bold">{children}</h2>;
const CardContent = ({ children }) => <div>{children}</div>;
const Input = (props) => <input {...props} className="border rounded px-3 py-2 w-full" />;
const Button = ({ children, ...props }) => (
  <button {...props} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
    {children}
  </button>
);

const formOptions = [
  { id: 'form1', title: 'Patient Registration Form' },
  { id: 'form2', title: 'OOP Form' },
  { id: 'form3', title: 'Form 3 Title' },
];

const API_KEY = 'f2517774c6321bf587f8e2a96fd4eb7c1048791c1331dc238447eed1cd75d7eb';
const API_BASE_URL = 'https://api.hellosign.com/v3';

const SignatureRequest = () => {
  const [emails, setEmails] = useState({
    form1: '',
    form2: '',
    form3: '',
  });
  const [status, setStatus] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [sentForms, setSentForms] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSignatureRequests();
  }, []);

  const fetchSignatureRequests = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/signature_request/list`, {
        auth: {
          username: API_KEY,
          password: ''
        }
      });
      console.log("response forms: ", response);
      const formattedForms = response.data.signature_requests.map(request => ({
        id: request.signature_request_id,
        formName: request.title || 'Untitled',
        date: new Date(request.created_at * 1000).toISOString().split('T')[0],
        status: request.is_complete ? 'Signed' : 'Pending',
        signee: request.signatures[0]?.signer_email_address || 'N/A'
      }));
      console.log("formatted forms: ", formattedForms);
      setSentForms(formattedForms);
    } catch (error) {
      console.error('Error fetching signature requests:', error);
      setStatus('Error fetching signature requests');
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (formId, value) => {
    setEmails(prev => ({ ...prev, [formId]: value }));
  };

  const handleSubmit = async (formId) => {
    setStatus(null);
  
    const email = emails[formId];
    if (!email) {
      setStatus(`Please enter an email for ${formOptions.find(f => f.id === formId).title}`);
      return;
    }
  
    try {
      const data = {
        test_mode: 1,
        title: formOptions.find(f => f.id === formId).title,
        subject: 'Please sign this document',
        message: 'Please sign this document at your earliest convenience',
        signers: [
          {
            email_address: email,
            name: 'Signer Name'
          }
        ],
        files: [
          {
            name: `${formId}.pdf`,
            // You would need to provide the actual file here
            file_url: `https://your-file-storage.com/${formId}.pdf`
          }
        ]
      };

      const response = await axios.post(`${API_BASE_URL}/signature_request/send`, data, {
        auth: {
          username: API_KEY,
          password: ''
        }
      });

      setStatus(`Signature request for ${formOptions.find(f => f.id === formId).title} sent successfully`);
      
      // Refresh the list of sent forms
      fetchSignatureRequests();
    } catch (error) {
      console.error('Error sending signature request:', error);
      setStatus(`Error: ${error.response?.data?.error?.message || error.message}`);
    }
  };

  return (
    <div className="h-screen bg-gray-100 flex flex-col">
      <Header />
      <div className="flex-grow flex overflow-hidden p-6 mt-20 space-x-20">
        {/* Left section: Send Signature Request */}
        <Card className=" flex flex-col mr-6">
          <CardHeader>
            <CardTitle>Send Signature Request</CardTitle>
          </CardHeader>
          <CardContent className=" flex-grow overflow-y-auto p-6">
            {formOptions.map((form) => (
              <div key={form.id} className="mb-6">
                <h3 className="font-semibold mb-3 text-lg">{form.title}</h3>
                <div className="flex flex-col space-y-3">
                  <Input
                    type="email"
                    value={emails[form.id]}
                    onChange={(e) => handleEmailChange(form.id, e.target.value)}
                    placeholder="Enter signer's email"
                  />
                  <Button onClick={() => handleSubmit(form.id)}>Send</Button>
                </div>
              </div>
            ))}
            {status && <p className="mt-4 text-sm text-blue-600">{status}</p>}
          </CardContent>
        </Card>

        {/* Right section: Sent Forms Status */}
        <Card className="w-7/12 flex flex-col h-full">
          <CardHeader>
            <CardTitle>Sent Forms Status</CardTitle>
          </CardHeader>
          <CardContent className="flex flex-col flex-grow overflow-hidden p-6">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div className="h-full flex flex-col">
                <div className="overflow-x-auto flex-shrink-0">
                  <Table>
                    <thead>
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Form Name</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Signee</th>
                      </tr>
                    </thead>
                  </Table>
                </div>
                <div className="overflow-y-auto flex-grow h-96"> {/* Adjust height as needed */}
                  <Table>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {sentForms.map((form) => (
                        <tr key={form.id}>
                          <td className="px-6 py-4 whitespace-nowrap">{form.formName}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{form.date}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{form.status}</td>
                          <td className="px-6 py-4 whitespace-nowrap">{form.signee}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default SignatureRequest;