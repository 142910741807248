import React, { useState, useEffect } from 'react';
import Header from './Header';

// Header Component
const HeaderX = () => (
  <header className="bg-blue-600 text-white p-4">
    <h1 className="text-xl font-bold">Chat App</h1>
  </header>
);

// Message Item Component
const MessageItem = ({ message }) => (
  <div className={`mb-4 ${message.isSent ? 'text-right' : ''}`}>
    <div className={`inline-block p-2 rounded-lg ${message.isSent ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}>
      {message.text}
    </div>
  </div>
);

// Message List Component
const MessageList = ({ messages }) => (
  <div className="p-4">
    {messages.map(message => (
      <MessageItem key={message.id} message={message} />
    ))}
  </div>
);

// Chat Input Component
const ChatInput = ({ sendMessage }) => {
  const [input, setInput] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      sendMessage(input);
      setInput('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="border-t p-4 bg-white">
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        className="w-full p-2 border rounded"
        placeholder="Type a message..."
      />
    </form>
  );
};

// Chat Window Component
const ChatWindow = ({ messages, sendMessage }) => (
  <div className="flex flex-col h-full">
    <div className="flex-1 overflow-y-auto">
      <MessageList messages={messages} />
    </div>
    <ChatInput sendMessage={sendMessage} />
  </div>
);

// Contact Item Component
const ContactItem = ({ contact, setActiveContact, isActive }) => (
  <li 
    className={`mb-2 p-2 cursor-pointer rounded ${isActive ? 'bg-blue-100' : 'hover:bg-gray-300'}`}
    onClick={() => setActiveContact(contact)}
  >
    {contact.name}
  </li>
);

// Sidebar Component
const Sidebar = ({ contacts, setActiveContact, activeContact }) => (
  <div className="p-4">
    <h2 className="text-lg font-semibold mb-4">Contacts</h2>
    <ul>
      {contacts.map(contact => (
        <ContactItem 
          key={contact.id} 
          contact={contact} 
          setActiveContact={setActiveContact}
          isActive={activeContact && activeContact.id === contact.id}
        />
      ))}
    </ul>
  </div>
);

// Main Chat Page Component
const ChatPage = () => {
  const [messages, setMessages] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [activeContact, setActiveContact] = useState(null);

  useEffect(() => {
    // Simulating fetching contacts
    setContacts([
      { id: 1, name: 'Farwa' },
      { id: 2, name: 'Michelle' },
      { id: 3, name: 'Jaimie' },
      { id: 4, name: 'Alexa' },
      { id: 5, name: 'Jill' },
    ]);
  }, []);

  const sendMessage = (text) => {
    const newMessage = {
      id: messages.length + 1,
      text,
      isSent: true,
    };
    setMessages([...messages, newMessage]);

    // Simulate receiving a reply
    setTimeout(() => {
      const reply = {
        id: messages.length + 2,
        text: `Simulated reply to: ${text}`,
        isSent: false,
      };
      setMessages(prevMessages => [...prevMessages, reply]);
    }, 1000);
  };

  return (
    <div className="flex flex-col h-screen">
      <Header />
      <div className="flex flex-1 overflow-hidden pt-6"> {/* Added pt-4 for top padding */}
        <div className="w-64 flex-shrink-0 overflow-y-auto bg-gray-200"> {/* Sidebar wrapper */}
          <Sidebar 
            contacts={contacts} 
            setActiveContact={setActiveContact}
            activeContact={activeContact}
          />
        </div>
        <div className="flex flex-col flex-1 pt-12">
          <ChatWindow messages={messages} sendMessage={sendMessage} />
        </div>
      </div>
    </div>
  );
};

export default ChatPage;