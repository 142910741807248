import React, { useState, useEffect, useRef } from 'react';
import { ClockIcon, MapPinIcon, UserIcon, CalendarIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
function ConfirmAppointmentModal({ isOpen, onClose, slot, endDate, onConfirm }) {
  const [patients, setPatients] = useState([]);
  const [originalPatients, setOriginalPatients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState('');
  const [selectedPatientName, setSelectedPatientName] = useState('');
  const [isPatientTableOpen, setIsPatientTableOpen] = useState(false);
  const searchInputRef = useRef(null);
  const patientTableRef = useRef(null);
  const [formData, setFormData] = useState({
    event_name: `${slot.service} Appointment`,
    event_description: '',
    provider_id: slot.providerId,
    patient_id: selectedPatientId,
    location: slot.location,
    service: slot.service,
    start_time: slot.start_time,
    end_time: slot.end_time,
    end_date: endDate,
    repeat_interval: null,
  });

  const fetchPatients = async () => {
    try {
        const response = await axios.get('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/listpatient');
        let patientsData = [];
        if (response.data) {
            if (Array.isArray(response.data)) {
                patientsData = response.data;
            } else if (typeof response.data === 'string') {
                patientsData = JSON.parse(response.data);
            } else if (response.data.body) {
                patientsData = JSON.parse(response.data.body);
            }
        }
        if (!Array.isArray(patientsData)) {
            console.error('Patients data is not an array:', patientsData);
            patientsData = [];
        }
        setPatients(patientsData);
        setOriginalPatients(patientsData);
    } catch (error) {
        console.error('Error fetching patients:', error);
        setPatients([]);
        setOriginalPatients([]);
    }
  };

  const filterPatients = (patients, searchTerm) => {
    if (!Array.isArray(patients)) return [];
    if (!searchTerm) return patients;

    return patients.filter(
        (patient) =>
            (patient.FirstName && patient.FirstName.toLowerCase().includes(searchTerm.toLowerCase())) ||
            (patient.LastName && patient.LastName.toLowerCase().includes(searchTerm.toLowerCase()))
    );
};

const handleSearchChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    const filteredResults = filterPatients(originalPatients, newSearchTerm);
    setPatients(filteredResults);
};

const handlePatientSelect = (patientId, patientFirst, patientLast) => {
    setSelectedPatientId(patientId);
    console.log("Selected Patient ID, ", patientId);
    setSelectedPatientName(`${patientFirst} ${patientLast}`);
    setSearchTerm('');
    setIsSearchFocused(false); // Close the search results
    setIsPatientTableOpen(false); // Hide patient table after selection
};

useEffect(() => {
  // Whenever selectedPatientId changes, update formData with the new patient_id
  setFormData((prevData) => ({
    ...prevData,
    patient_id: selectedPatientId
  }));
}, [selectedPatientId]);


const handleSearchFocus = () => {
    setIsSearchFocused(true);
    setIsPatientTableOpen(true);
};

const handleClickOutside = (event) => {
    if (searchInputRef.current && !searchInputRef.current.contains(event.target) &&
        patientTableRef.current && !patientTableRef.current.contains(event.target)) {
        setIsPatientTableOpen(false);
    }
};

useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, []);

useEffect(() => {
  fetchPatients();
}, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: name === 'repeat_interval' ? (value === 'Weekly' ? 604800 : null) : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/create_event', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      console.log('Appointment created:', result);
      onConfirm();
      onClose();
    } catch (error) {
      console.error('Error creating appointment:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-2/3 shadow-lg rounded-md bg-white">
        <h3 className="text-lg font-bold mb-4">Confirm Appointment</h3>
        <div className="bg-white border border-gray-200 rounded-lg p-4 hover:shadow-md transition-shadow duration-300 cursor-pointer">
            <div className="flex flex-col space-y-4">
                <div className="flex items-center space-x-2">
                    <UserIcon className="w-5 h-5 text-blue-500" />
                    <span className="font-semibold">{slot.providerName}</span>
                    <span className="text-gray-500 text-xs">({slot.providerId})</span>
                </div>
                <div className="flex items-center space-x-2">
                    <MapPinIcon className="w-5 h-5 text-green-500" />
                    <span className="text-sm">Location: {slot.location}</span>
                </div>
                <div className="flex items-center space-x-2">
                    <CalendarIcon className="w-5 h-5 text-purple-500" />
                    <span className="text-sm">Service: {slot.service}</span>
                </div>
                <div className="flex items-center space-x-2">
                  <ClockIcon className="w-5 h-5 text-orange-500" />
                  <span className="text-sm">
                      {new Date(slot.start_time).toLocaleDateString()} - {new Date(endDate).toLocaleDateString()} from{" "} 
                      {new Date(slot.start_time).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })} - {" "}
                      {new Date(slot.end_time).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })} on {" "}
                      {new Date(slot.start_time).toLocaleDateString("en-US", { weekday: 'long' })}'s
                  </span>
                </div>
            </div>
          </div>
        
        <p className="mt-4 mb-2 font-semibold">We just need some more information...</p>
        <div className="pl-2 flex w-full h-12 rounded-lg bg-white overflow-hidden">
                <div className="grid place-items-center h-full w-12 text-gray-300">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </div>
                <input
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onFocus={handleSearchFocus}
                    placeholder="Search patients..."
                    ref={searchInputRef}
                />
            </div>
            
            {isPatientTableOpen && (
                <div ref={patientTableRef} className="h-[250px] overflow-y-auto border border-gray-200 rounded-lg mt-2">
                    <table className="w-full min-w-max table-auto text-left">
                        <thead className="sticky top-0 bg-white">
                            <tr>
                                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">First Name</th>
                                <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">Last Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {patients.map((patient) => (
                                <tr
                                    key={patient.PatientID}
                                    onClick={() => handlePatientSelect(patient.PatientID, patient.FirstName, patient.LastName)}
                                    className="cursor-pointer hover:bg-gray-100 h-[50px]"
                                >
                                    <td className="font-normal p-4">{patient.FirstName}</td>
                                    <td className="font-normal p-4">{patient.LastName}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            <div class="relative w-full my-4">
              <input name="patient_id" type="text" value={selectedPatientName} onChange={handleChange} id="patient_id" placeholder=" " required class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer" />
              <label for="patient_id" class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Patient Name</label>
            </div>
            <div className="relative w-full mb-4">
              <select
                name="repeat_interval"
                value={formData.repeat_interval === 604800 ? 'Weekly' : 'None'}
                onChange={handleChange}
                id="repeat_interval"
                required
                className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              >
                <option value="None">None</option>
                <option value="Weekly">Weekly</option>
              </select>
              <label
                htmlFor="repeat_interval"
                className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
              >
                Repeat Interval
              </label>
            </div>

        <div className="mt-4">
          <button
            onClick={handleSubmit}
            className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
          >
            Confirm
          </button>
          <button
            onClick={onClose}
            className="bg-gray-300 text-black px-4 py-2 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmAppointmentModal;