import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';

const CaptureCharge = () => {
  const { id } = useParams();
  const location = useLocation();
  const { patientID, note, providerName } = location.state || {};

  const [patientInfo, setPatientInfo] = useState(null);
  const [diagnosisCodes, setDiagnosisCodes] = useState([{ rank: '', code: '' }]);
  const [procedureCodes, setProcedureCodes] = useState([{
    code: '', mod1: '', mod2: '', mod3: '', units: '',
    charge: '', total: '', diagnosis1: '', diagnosis2: '', diagnosis3: ''
  }]);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("Patient ID: ", patientID);
    const fetchPatientInfo = async () => {
      try {
        const response = await axios.get('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/find_patient', {
          headers: {
            PatientID: patientID.toString() // Ensure patientID is a string
          }
        });
        console.log("API Response: ", response);
        if (response.data && response.data.body) {
            let patientData;
            if (typeof response.data.body === 'string') {
              patientData = JSON.parse(response.data.body);
            } else {
              patientData = response.data.body;
            }
            
            console.log("Patient Data: ", patientData);
            
            if (Array.isArray(patientData) && patientData.length > 0) {
              setPatientInfo(patientData[0]);
              console.log("Patient Info: ", patientData[0]);
            } else {
              setError("No patient data found in the response");
            }
        } else {
          console.log("No patient data received");
        }
      } catch (error) {
        console.error('Error fetching patient info:', error);
      }
    };

    if (patientID) {
      fetchPatientInfo();
    }
  }, [patientID]);

  useEffect(() => {
    console.log("Patient Info: ", patientInfo);
  }, [patientInfo]);

  const addDiagnosisCode = () => {
    if (diagnosisCodes.length < 12) {
      setDiagnosisCodes([...diagnosisCodes, { rank: '', code: '' }]);
    }
  };

  const updateDiagnosisCode = (index, field, value) => {
    const updatedCodes = [...diagnosisCodes];
    updatedCodes[index][field] = value;
    setDiagnosisCodes(updatedCodes);
  };

  const addProcedureCode = () => {
    setProcedureCodes([...procedureCodes, {
      code: '', mod1: '', mod2: '', mod3: '', units: '',
      charge: '', total: '', diagnosis1: '', diagnosis2: '', diagnosis3: ''
    }]);
  };

  const updateProcedureCode = (index, field, value) => {
    const updatedCodes = [...procedureCodes];
    updatedCodes[index][field] = value;
    setProcedureCodes(updatedCodes);
  };

  return (
    <div>
      <Header />
      <div className="container mx-auto pt-24">
        <h1 className="text-2xl font-bold mb-4">Capture Charge</h1>
        
        {/* Section 1: Patient Information */}
        <div className="mb-8 pb-4 border-b-2 border-gray-300">
          <h2 className="text-xl font-semibold mb-4">Patient Information</h2>
          {patientInfo ? (
            <div>
                <p><strong>Name:</strong> {patientInfo.FirstName} {patientInfo.LastName}</p>
                <p><strong>Gender:</strong> {patientInfo.Gender}</p>
                <p><strong>DOB:</strong> {patientInfo.DOB}</p>
                <p><strong>Insurance Company:</strong> {patientInfo.InsuranceCompany}</p>
                <p><strong>Phone Number:</strong> {patientInfo.PhoneNumber}</p>
                <p><strong>Email:</strong> {patientInfo.Email}</p>
                <p><strong>Parent/Guardian:</strong> {patientInfo.ParentGuardian}</p>
                <p><strong>Relationship:</strong> {patientInfo.Relationship}</p>
                <p><strong>School:</strong> {patientInfo.School}</p>
                <p><strong>Services:</strong> {patientInfo.Services}</p>
            </div>
            ) : (
            <p>Loading patient information...</p>
            )}
        </div>

        {/* Section 2: Event Information */}
        <div className="mb-8 pb-4 border-b-2 border-gray-300">
          <h2 className="text-xl font-semibold mb-4">Event Information</h2>
          <p><strong>Provider Name:</strong> {providerName}</p>
          <p><strong>Date of Service:</strong> {note?.date_service}</p>
          <p><strong>Field1:</strong> {/* Add Field1 data here */}</p>
          <p><strong>Field2:</strong> {/* Add Field2 data here */}</p>
        </div>

        {/* Section 3: Diagnosis Codes */}
        <div className="mb-8 pb-4 border-b-2 border-gray-300">
          <h2 className="text-xl font-semibold mb-4">Diagnosis Codes (Max 12)</h2>
          <table className="w-full mb-4">
            <thead>
              <tr>
                <th className="w-1/4 text-left">Rank</th>
                <th className="w-3/4 text-left">Diagnosis Code</th>
              </tr>
            </thead>
            <tbody>
              {diagnosisCodes.map((code, index) => (
                <tr key={index}>
                  <td className="w-1/4 pr-2">
                    <input
                      type="text"
                      value={code.rank}
                      onChange={(e) => updateDiagnosisCode(index, 'rank', e.target.value)}
                      className="w-full p-1 border rounded"
                    />
                  </td>
                  <td className="w-3/4">
                    <input
                      type="text"
                      value={code.code}
                      onChange={(e) => updateDiagnosisCode(index, 'code', e.target.value)}
                      className="w-full p-1 border rounded"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button 
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={addDiagnosisCode}
            disabled={diagnosisCodes.length >= 12}
          >
            Add Diagnosis Code
          </button>
        </div>

        {/* Section 4: Procedure Codes */}
        <div className='pb-4'>
          <h2 className="text-xl font-semibold mb-4">Procedure Codes</h2>
          <div className="overflow-x-auto">
            <table className="w-full mb-4">
              <thead>
                <tr>
                  <th className="w-2/12 text-left">Procedure Code</th>
                  <th className="w-1/12 text-left">Mod 1</th>
                  <th className="w-1/12 text-left">Mod 2</th>
                  <th className="w-1/12 text-left">Mod 3</th>
                  <th className="w-1/12 text-left">Units</th>
                  <th className="w-1/12 text-left">Charge</th>
                  <th className="w-1/12 text-left">Total</th>
                  <th className="w-1/12 text-left">Diagnosis 1</th>
                  <th className="w-1/12 text-left">Diagnosis 2</th>
                  <th className="w-1/12 text-left">Diagnosis 3</th>
                </tr>
              </thead>
              <tbody>
                {procedureCodes.map((procedure, index) => (
                  <tr key={index}>
                    {Object.keys(procedure).map((field) => (
                      <td key={field} className={field === 'code' ? 'w-2/12 pr-2' : 'w-1/12 pr-2'}>
                        <input
                          type="text"
                          value={procedure[field]}
                          onChange={(e) => updateProcedureCode(index, field, e.target.value)}
                          className="w-full p-1 border rounded"
                        />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button 
            className="bg-blue-500 text-white px-4 py-2 rounded"
            onClick={addProcedureCode}
          >
            Add Procedure Code
          </button>
        </div>
      </div>
    </div>
  );
};

export default CaptureCharge;