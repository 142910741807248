import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './Header';
import EmployeeProfileTabs from './EmployeeProfileTabs';

const EmployeeProfile = () => {
  const { state } = useLocation();
  const { employee } = state;
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    // Retrieve button state from localStorage on component mount
    const storedIsActive = localStorage.getItem('buttonState');
    if (storedIsActive !== null) {
      setIsActive(JSON.parse(storedIsActive));
    }
  }, []);

  const handleStatusClick = () => {
    setIsActive((prevState) => {
      // Update button state in localStorage
      localStorage.setItem('buttonState', JSON.stringify(!prevState));
      return !prevState;
    });
  };

  return (
    <div className="flex flex-col relative h-screen">
      <Header />
      <div className="pt-4 pl-4 h-1/2">
        <div className="flex items-center space-x-8">
          <div className="relative">
            <img
              alt={`${employee.Name}`}
              src="https://via.placeholder.com/150"
              className="rounded-full h-64 w-64 object-cover"
            />
            <button
              className={`absolute bottom-0 left-1/2 transform -translate-x-1/2 px-4 py-2 rounded-md text-white ${
                isActive
                  ? 'bg-green-500 hover:bg-green-600'
                  : 'bg-red-500 hover:bg-red-600'
              }`}
              onClick={handleStatusClick}
            >
              {isActive ? 'Active' : 'Inactive'}
            </button>
          </div>
          <div>
            <h1 className="text-4xl font-bold mb-2">
              {employee.Name}
            </h1>
            <div className="text-lg">
              <p>Director of Operations</p>
              <p>Speech Language Pathologist</p>
              <p>Location: {employee.Location}</p>
            </div>
          </div>
        </div>
      </div>
      <EmployeeProfileTabs />
    </div>
  );
};

export default EmployeeProfile;