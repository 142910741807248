import Header from "./Header";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AddPatientPopUp from "./AddPatientPopUp";

const AllPatients = () => {
  const [patients, setPatients] = useState([]);
  const [originalPatients, setOriginalPatients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('Active');
  const navigate = useNavigate();
  const [autoPatientID, setAutoPatientID] = useState('');
  const [openPopup, setOpenPopup] = useState(false);
  const HandleRemovePopUp = () => setOpenPopup(false);

  const tabs = ['Pending', 'New', 'Active', 'Waiting List', 'Inactive'];

  const fetchPatients = async () => {
    try {
      const response = await axios.get('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/listpatient');
      
      if (response.data) {
        const patientsData = Array.isArray(response.data) ? response.data : JSON.parse(response.data.body);
        setOriginalPatients(patientsData);
        const activePatients = filterPatients(patientsData, '', 'Active');
        setPatients(activePatients);
      } else {
        console.error('API response has no data');
      }
    } catch (error) {
      console.error('Error fetching patients:', error);
    }
  };

  const filterPatients = (patients, searchTerm, status) => {
    return patients.filter(
      (patient) =>
        patient.InactivePt === status &&
        (!searchTerm ||
          (patient.FirstName && patient.FirstName.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (patient.LastName && patient.LastName.toLowerCase().includes(searchTerm.toLowerCase())))
    );
  };

  const handleSearchChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    
    const filteredResults = filterPatients(originalPatients, newSearchTerm, activeTab);
    setPatients(filteredResults);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    const filteredResults = filterPatients(originalPatients, searchTerm, tab);
    setPatients(filteredResults);
  };

  useEffect(() => {
    fetchPatients();
  }, []);

  const handlePatientClick = (patient) => {
    navigate(`/patients/${patient.PatientID}`, {state: { patient } });
  };

  const getMaxPatientID = (patients) => {
    if (patients.length === 0) return 0;
    const patientIDs = patients.map((patient) => parseInt(patient.PatientID));
    return Math.max(...patientIDs);
  };

  const openAddForm = () => {
    const maxPatientID = getMaxPatientID(originalPatients);
    const newPatientID = (maxPatientID + 1).toString();
    setAutoPatientID(newPatientID);
    setOpenPopup(true);
  };

  return (
    <div className="py-16 flex flex-col relative h-screen">
      <Header />
      <h1 className="text-center">Patients Database</h1>
      <div className="flex flex-col justify-center">
        <div className="flex justify-center mb-4">
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={openAddForm}
          >
            Add Patient
          </button>
        </div>
        <AddPatientPopUp 
          openPopUp={openPopup} 
          closePopUp={HandleRemovePopUp} 
          fetchPatients={fetchPatients}
          autoPatientID={autoPatientID}
          patients={originalPatients}
        />

        <div className="flex w-full h-12 rounded-lg bg-white overflow-hidden mb-4">
          <div className="grid place-items-center h-full w-12 text-gray-300">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
          </div>
          <input
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search patients..."
          />
        </div>

        <div className="flex justify-center mb-4">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`px-4 py-2 mr-2 rounded ${
                activeTab === tab
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
              }`}
              onClick={() => handleTabChange(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">First Name</th>
              <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">Last Name</th>
              <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">Email</th>
              <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">Status</th>
            </tr>
          </thead>
          <tbody>
            {patients.map((patient) => (
              <tr key={patient.PatientID} onClick={() => handlePatientClick(patient)} className="cursor-pointer hover:bg-gray-100">
                <td className="font-normal p-4">{patient.FirstName}</td>
                <td className="font-normal p-4">{patient.LastName}</td>
                <td className="font-normal p-4">{patient.Email}</td>
                <td className="font-normal p-4">{patient.InactivePt}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllPatients;