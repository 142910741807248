import React, { useState, useEffect, Fragment } from 'react';
import { format, startOfWeek,endOfWeek, addDays, isSameDay, getWeek, addWeeks, subWeeks, parseISO, isWithinInterval, differenceInMinutes } from 'date-fns';
import Header from "./Header";
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, Cog6ToothIcon } from '@heroicons/react/24/solid';
import { Menu, MenuItem, MenuButton, MenuItems, Transition } from '@headlessui/react';
import { UserIcon, MapPinIcon, CalendarIcon, ClockIcon, BoltIcon, CubeIcon } from '@heroicons/react/24/outline';
import CreateEventModal from './CreateEventModal';
import axios from 'axios';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const WeekView = () => {
    const navigate = useNavigate();
    const [showCreateEvent, setShowCreateEvent] = useState(false);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [providerName, setProviderName] = useState('');
    const [location, setLocation] = useState('');
    const [specialty, setSpecialty] = useState('');
    const [getProviders, setGetProviders] = useState([]);
    const [provider, setProvider] = useState('');
    const [events, setEvents] = useState([]);
    const [selectedProvID, setSelectedProvId] = useState(null);
    const [today, setToday] = useState(new Date());

    const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const hours = Array.from({ length: 24 }, (_, i) => 8 + i / 2);
    

    const weekStart = startOfWeek(currentDate, { weekStartsOn: 0 });

  const nextWeek = () => {
    const newDate = addWeeks(currentDate, 1);
    setCurrentDate(newDate);
    handleProviderOrDateChange(selectedProvID, newDate);
  };

  const prevWeek = () => {
    const newDate = subWeeks(currentDate, 1);
    setCurrentDate(newDate);
    handleProviderOrDateChange(selectedProvID, newDate);
  };

  const goToday = () => {
    const newDate = new Date();
    setCurrentDate(newDate);
    handleProviderOrDateChange(selectedProvID, newDate);
  };

  const updateCurrentDate = (date) => {
    setCurrentDate(date);
    setSelectedDate(date);
    handleProviderOrDateChange(selectedProvID, date);
  };

  const onDateClick = (day) => {
    setCurrentDate(day);
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
  
    try {
      const params = new URLSearchParams();
      if (location) params.append('location', location);
      if (specialty) params.append('specialty', specialty);
      if (providerName) params.append('name', providerName + '%');
  
      const response = await axios.get(`https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_providers?${params.toString()}`);
  
      let providersArray = [];
  
      if (typeof response.data === 'string') {
        // Custom parsing for the specific string format
        const match = response.data.match(/body=(\[.*?\])/s);
        if (match && match[1]) {
          try {
            const bodyContent = match[1].replace(/'/g, '"'); // Replace single quotes with double quotes
            const parsedBody = JSON.parse(bodyContent);
            if (Array.isArray(parsedBody)) {
              providersArray = parsedBody.map(provider => ({
                id: provider.pr_id,
                name: provider.pr_name
              }));
            }
          } catch (parseError) {
            console.error('Error parsing body content:', parseError);
          }
        } else {
          console.error('Unable to extract body content from response');
        }
      } else if (typeof response.data === 'object' && response.data !== null) {
        // Handle case where response.data is already an object
        if (response.data.body && Array.isArray(response.data.body)) {
          providersArray = response.data.body.map(provider => provider.pr_name);
        } else {
          console.error('Unexpected data structure:', response.data);
        }
      } else {
        console.error('Unexpected response type:', typeof response.data);
      }
  
      setGetProviders(providersArray);
    } catch (error) {
      console.error('Error calling Lambda function:', error);
      setGetProviders([]);
    }
  };

  const fetchEvents = async (payload) => {
    console.log('Payload: ', payload);
    try {
      const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get-provider-events', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Lambda function response:', data);
      
      // Parse JSON string (data) into an array
      const parsedData = JSON.parse(data);
      console.log('Parsed data: ', parsedData);

      setEvents(parsedData);
    } catch (error) {
      console.error('Error calling Lambda function: ', error);
      setEvents([]);
    }
  };

  const handleProviderOrDateChange = (newProviderId, newDate) => {
    const startDayOfWeek = startOfWeek(newDate, { weekStartsOn: 0 });
    const formattedDate = format(startDayOfWeek, 'yyyy-MM-dd');

    const endDayOfWeek = endOfWeek(newDate, { weekStartsOn: 0 });
    const formattedPlus = format(endDayOfWeek, 'yyyy-MM-dd');


    const provId = newProviderId || selectedProvID;

    if (provId) {
      const payload = {
        provider_id: parseInt(provId),
        start_date: formattedDate,
        end_date: formattedPlus
      };

      fetchEvents(payload);
    } else {
      setEvents([]);
    }
  };

  const handleEventClick = (event) => {
    const startDate = new Date(event.start_time);
    const formattedDate = format(startDate, 'yyyy-MM-dd');
    const eventId = `${event.event_id}-${event.event_schedule_id}-${formattedDate}`;
    const currentPage = "WeekView";
    navigate(`/modifyevent/${eventId}`, {
      state: { event, page: currentPage } 
    });
  };

  const renderEvent = (event, dayIndex, hour) => {
    const eventStart = parseISO(event.start_time);
    const eventEnd = parseISO(event.end_time);
    const cellStart = new Date(addDays(weekStart, dayIndex).setHours(Math.floor(hour), hour % 1 ? 30 : 0));
    const cellEnd = new Date(cellStart.getTime() + 30 * 60000); // 30 minutes later

    // Check if the event overlaps with this cell
    if (eventStart < cellEnd && eventEnd > cellStart) {
        const eventDurationInMinutes = differenceInMinutes(eventEnd, eventStart);
        const cellOverlapStart = eventStart < cellStart ? cellStart : eventStart;
        const cellOverlapEnd = eventEnd > cellEnd ? cellEnd : eventEnd;
        const overlapDurationInMinutes = differenceInMinutes(cellOverlapEnd, cellOverlapStart);
        
        // Calculate height based on total event duration
        const heightPercentage = (eventDurationInMinutes / 30) * 100; // 30 minutes is the full height of the cell
        
        // Calculate top offset
        const topOffset = differenceInMinutes(cellOverlapStart, cellStart) / 30 * 100;

        // Only render the event once, at its start time
        if (hour === Math.floor(eventStart.getHours() + eventStart.getMinutes() / 60)) {
            let status;
            if (event.ev_status === 'A') {
                status = "Scheduled";
            } else if (event.ev_status === 'C') {
                status = event.cancel_reason;
            } else if (event.ev_status === 'Y') {
                status = "Confirmed";
            } else {
                status = "unknown";
            }

            // Determine if the event is an hour or longer
            const isLongEvent = eventDurationInMinutes >= 60;

            return (
                <div
                    className={`absolute left-0 right-0 bg-white border border-gray-200 rounded-sm p-1 overflow-hidden hover:shadow-md transition-shadow duration-300 cursor-pointer ${isLongEvent ? 'text-sm' : 'text-xs'}`}
                    style={{
                        top: `${topOffset}%`,
                        height: `${heightPercentage}%`,
                        zIndex: 10
                    }}
                    onClick={() => handleEventClick(event)}

                >
                    <div className="flex flex-col h-full">
                        <div className={`flex items-center space-x-1 ${isLongEvent ? 'mb-2' : ''}`}>
                            <UserIcon className={`${isLongEvent ? 'w-4 h-4' : 'w-3 h-3'} text-blue-500`} />
                            <span className="font-semibold truncate">{event.event_name}</span>
                        </div>
                        <div className={`flex items-center space-x-1 ${isLongEvent ? 'mb-2' : ''}`}>
                            <MapPinIcon className={`${isLongEvent ? 'w-4 h-4' : 'w-3 h-3'} text-green-500`} />
                            <span className="truncate">{event.ev_location || 'N/A'}</span>
                        </div>
                        <div className={`flex items-center space-x-1 ${isLongEvent ? 'mb-2' : ''}`}>
                            <BoltIcon className={`${isLongEvent ? 'w-4 h-4' : 'w-3 h-3'} text-purple-500`} />
                            <span className="truncate">{status || 'N/A'}</span>
                        </div>
                        <div className={`flex items-center space-x-1 ${isLongEvent ? 'mb-2' : ''}`}>
                            <ClockIcon className={`${isLongEvent ? 'w-4 h-4' : 'w-3 h-3'} text-orange-500`} />
                            <span className="truncate">
                                {format(eventStart, 'h:mm a')} - {format(eventEnd, 'h:mm a')}
                            </span>
                        </div>
                        {isLongEvent && (
                          <div className={`flex items-center space-x-1 ${isLongEvent ? 'mb-2' : ''}`}>
                              <CubeIcon className={`${isLongEvent ? 'w-4 h-4' : 'w-3 h-3'} text-purple-500`} />
                              <span className="truncate">Patient</span>
                          </div>
                        )}
                    </div>
                </div>
            );
        }
    }
    return null;
};

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <div className="flex flex-col h-screen mt-20">
        <Header />
        <div className="flex-grow flex flex-col overflow-hidden">
            <div className="flex-shrink-0 sticky top-0 bg-white z-20 shadow">
                {/* Combined calendar controls and provider selection */}
                <div className="flex items-center justify-between py-4 px-6">
                    <div className="flex items-center space-x-4">
                        <div>
                            <h1 className="text-lg font-semibold leading-6 text-gray-900">
                                <time className="sm:inline">
                                    {format(weekStart, 'MMMM d, yyyy')} - {format(addDays(weekStart, 6), 'MMMM d, yyyy')}
                                </time>
                            </h1>
                            <p className="mt-1 text-sm text-gray-500">Today {format(today, '(EEEE) - MMM dd, yyy')}</p>
                        </div>
                        <div className="flex items-center rounded-md shadow-sm md:items-stretch">
                            <button
                                type="button"
                                className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                                onClick={prevWeek}
                            >
                                <span className="sr-only">Previous Week</span>
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                            <button
                                type="button"
                                onClick={goToday}
                                className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
                            >
                                Today
                            </button>
                            <button
                                type="button"
                                onClick={nextWeek}
                                className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
                            >
                                <span className="sr-only">Next Week</span>
                                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="hidden md:ml-4 md:flex md:items-center">
                          <Menu as="div" className="relative">
                            <MenuButton
                              type="button"
                              className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                            >
                              Week View
                              <ChevronDownIcon
                                className="ml-2 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </MenuButton>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <MenuItems className="focus:outline-none absolute right-0 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="py-1">
                                  <MenuItem>
                                      <Link to="/calendar" className='text-gray-700 block px-4 py-2 text-sm'>
                                        Day View
                                      </Link>
                                  </MenuItem>
                                  <MenuItem>
                                      <Link to="/weekview" className='text-gray-700 block px-4 py-2 text-sm'>
                                        Week View
                                      </Link>
                                  </MenuItem>
                                  <MenuItem>
                                      <Link to="/twoprovview" className='text-gray-700 block px-4 py-2 text-sm'>
                                        Month View
                                      </Link>
                                  </MenuItem>
                                  <MenuItem>
                                      <Link to="/twoprovview" className='text-gray-700 block px-4 py-2 text-sm'>
                                        Two Provider View
                                      </Link>
                                  </MenuItem>
                                </div>
                              </MenuItems>
                            </Transition>
                          </Menu>
                        </div>
                    </div>
                    <div className="flex items-center space-x-4">
                        {/* Provider selection */}
                        <div className="flex items-center">
                            <label htmlFor="provider" className="block mr-2 text-sm font-medium text-gray-900 dark:text-black">Provider</label>
                            <select 
                                value={provider} 
                                onChange={(e) => {
                                    const selectedProvider = getProviders.find(p => p.name === e.target.value);
                                    setProvider(e.target.value);
                                    const newProviderId = selectedProvider ? selectedProvider.id : null;
                                    setSelectedProvId(newProviderId);
                                    handleProviderOrDateChange(newProviderId, currentDate);
                                }} 
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                                id="grid-state"
                            >
                                <option>None</option>
                                {getProviders.length > 0 ? (
                                    getProviders.map((provider) => (
                                        <option key={provider.id} value={provider.name}>
                                            {provider.name}
                                        </option>
                                    ))
                                ) : (
                                    <option>No providers available</option>
                                )}
                            </select>
                        </div>
                        {/* Add event button */}
                        <button
                            type="button"
                            onClick={() => setShowCreateEvent(true)}
                            className="focus:outline-none rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                            Add Event
                        </button>
                    </div>
                </div>
            </div>
            <div className="flex-grow overflow-auto">
                <div className="inline-block min-w-full align-middle">
                    <table className="min-w-full divide-y divide-gray-300 table-fixed">
                        <thead className="sticky top-0 bg-white z-30">
                            <tr>
                                <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">Time</th>
                                {days.map((day, index) => {
                                    const date = addDays(weekStart, index);
                                    return (
                                        <th
                                            key={day}
                                            className={`py-3.5 px-20 text-center ${
                                                isSameDay(date, currentDate) ? 'bg-blue-100' : ''
                                            }`}
                                        >
                                            <div className="font-semibold">{day}</div>
                                            <div className={`text-2xl ${
                                                isSameDay(date, currentDate) ? 'text-blue-500' : ''
                                            }`}>
                                                {format(date, 'd')}
                                            </div>
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {hours.map((hour) => {
                                let formattedHour = format(new Date().setHours(Math.floor(hour), hour % 1 ? 30 : 0), 'h:mm a');
                                return (
                                    <tr key={hour}>
                                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                            {formattedHour}
                                        </td>
                                        {days.map((_, index) => (
                                            <td key={`${index}-${hour}`} className="p-0 text-sm text-gray-500">
                                                <div className="h-20 bg-gray-100 border border-gray-200 relative">
                                                    {events.map((event, eventIndex) => renderEvent(event, index, hour))}
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <CreateEventModal
            isOpen={showCreateEvent}
            onClose={() => setShowCreateEvent(false)}
        />
    </div>
);
};

export default WeekView;