import React, { useState } from 'react';
import { Bars3BottomRightIcon, XMarkIcon, UserIcon, LinkIcon } from '@heroicons/react/24/solid'
import { Authenticator } from '@aws-amplify/ui-react';
import { HomeIcon, UsersIcon, FolderIcon, CalendarIcon, PencilSquareIcon, CreditCardIcon, ChartBarIcon, BellAlertIcon, ChatBubbleLeftIcon, ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { useUser } from './UserContext';
import { Link } from 'react-router-dom';

const Header = () => {
    const { userName, userRole } = useUser();
    let Links =[
        { name: 'Dashboard', link: '/Home', icon: HomeIcon },
        { name: 'Alerts', link: '/Alerts', icon: BellAlertIcon},
        { name: 'Patients', link: '/Patients', icon: UsersIcon},
        //{ name: 'Chat', link: '/Chat', icon: ChatBubbleLeftIcon},
        { name: 'Calendar', link: '/Calendar', icon: CalendarIcon},
        { name: 'HR', link: '/HR', icon: ChartBarIcon},
        { name: 'Forms', link: '/Forms', icon: ClipboardDocumentIcon},
        { name: 'Charge', link: '/chargingtable', icon: CreditCardIcon},
        { name: 'Office Notes', link: '/departmentnotes', icon: PencilSquareIcon},
      ];
      let [open, setOpen] =useState(false);

    return (
        <div className='shadow-md w-full fixed top-0 left-0'>
           <div className='md:flex items-center justify-between bg-white py-4 md:px-10 px-7'>
            {/* logo section */}
            <div className='font-bold text-2xl flex items-center gap-1'>
                <img src="/sensorylogo.png" className="w-20 h-13"/>
                <span>MaxStudioHealth</span>
            </div>
            {/* Menu icon */}
            <div onClick={()=>setOpen(!open)} className='absolute right-8 top-6 cursor-pointer md:hidden w-7 h-7'>
                {
                    open ? <XMarkIcon/> : <Bars3BottomRightIcon />
                }
            </div>
            {/* linke items */}
            <ul className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${open ? 'top-12' : 'top-[-490px]'}`}>
                {
                    Links.map((link) => (
                    <li className='md:ml-8 md:my-0 my-7 font-semibold' key={link.name}>
                        <Link to={link.link} className='flex text-gray-800 hover:text-blue-400 duration-500'>
                            {link.icon && <link.icon className="h-5 w-5 mr-1" />}
                            {link.name}
                        </Link>
                    </li>))
                }
                <Authenticator>         
                {({ signOut }) => (
                    <a
                    href=""
                    onClick={signOut}
                    className='px-8 text-black text-bold'
                    >
                    Sign out
                    </a>
                )}
                </Authenticator>
            </ul>
            {/* button */}
           </div>
        </div>
    );
};

export default Header;