import React, { useState } from 'react';
import { PaymentForm, CreditCard } from 'react-square-web-payments-sdk';
import Header from './Header';

const Charge = () => {
  const [paymentAmount, setPaymentAmount] = useState('');
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [error, setError] = useState(null);

  const handlePaymentSubmit = async (token) => {
    setPaymentStatus(null);
    setError(null);
  
    try {
      const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/square-payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          body: JSON.stringify({
            sourceId: token.token,
            amount: Math.round(parseFloat(paymentAmount) * 100),
            currency: 'USD',
          })
        }),
      });
  
      const result = await response.json();
  
      if (!response.ok) {
        throw new Error(result.error || 'Payment failed');
      }
  
      console.log(result);
      setPaymentStatus('Payment successful');
    } catch (error) {
      console.error('Error creating payment:', error);
      setError(error.message || 'An error occurred while processing the payment');
    }
  };

  return (
    <div>
      <Header />
      <div className="container mx-auto p-4 mt-20">
        <h1 className="text-2xl font-bold mb-4">Process Payment</h1>
        <div className="mb-4">
          <label htmlFor="amount" className="block mb-2">Payment Amount ($)</label>
          <input
            type="number"
            id="amount"
            value={paymentAmount}
            onChange={(e) => setPaymentAmount(e.target.value)}
            className="w-full p-2 border rounded"
            placeholder="Enter amount"
          />
        </div>
        <PaymentForm
          applicationId="sandbox-sq0idb-jvKqLcpPJqY71l_PE2Imtg"
          locationId="LBXEQE0RNVHPF" // Replace with your actual location ID
          environment="sandbox"
          cardTokenizeResponseReceived={async (token, buyer) => {
            await handlePaymentSubmit(token);
          }}
        >
          <CreditCard />
        </PaymentForm>
        {paymentStatus && (
          <div className="mt-4 p-2 bg-green-100 text-green-700 rounded">
            {paymentStatus}
          </div>
        )}
        {error && (
          <div className="mt-4 p-2 bg-red-100 text-red-700 rounded">
            {error}
          </div>
        )}
      </div>
    </div>
  );
};

export default Charge;