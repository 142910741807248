import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Header from './Header';
import { Bell, Share2, Lock, Users, Edit2, X } from 'lucide-react';

// Simulated real-time collaboration service
const collaborationService = {
  subscribe: (docId, callback) => {
    const ws = new WebSocket('your-websocket-url');
    ws.onmessage = (event) => callback(JSON.parse(event.data));
    return () => ws.close();
  },
  
  broadcastChange: (docId, change) => {
    // Implement your broadcast logic here
  }
};

// Enhanced document service
const documentService = {
  async saveDocument(docId, data) {
    return new Promise((resolve) => {
      setTimeout(() => {
        localStorage.setItem(`doc_${docId}`, JSON.stringify(data));
        resolve({ success: true });
      }, 500);
    });
  },

  async loadDocument(docId) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const data = localStorage.getItem(`doc_${docId}`);
        resolve(data ? JSON.parse(data) : {
          title: 'Untitled Document',
          content: '',
          lastSaved: null,
          permissions: {
            owner: 'current-user',
            readers: [],
            editors: []
          }
        });
      }, 200);
    });
  }
};

// Custom Modal Component
const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">{title}</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-5 w-5" />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

const DepartmentNotes = () => {
    const [documents, setDocuments] = useState({
      tab1: { title: 'Front Desk', content: '', lastSaved: null, permissions: {} },
      tab2: { title: 'Billing', content: '', lastSaved: null, permissions: {} },
      tab3: { title: 'Admin', content: '', lastSaved: null, permissions: {} },
      tab4: { title: 'Parent/Provider', content: '', lastSaved: null, permissions: {} },
      tab5: { title: 'Supervision', content: '', lastSaved: null, permissions: {} }
    });
    
    const [activeTab, setActiveTab] = useState('tab1');
    const [isSaving, setIsSaving] = useState(false);
    const [error, setError] = useState(null);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [editingTitleValue, setEditingTitleValue] = useState(''); // New state for title editing
    const [showShareDialog, setShowShareDialog] = useState(false);
    const [activeUsers, setActiveUsers] = useState(new Set());
    const [lastSavedContent, setLastSavedContent] = useState(''); // Track last saved content
  
    // Initialize TipTap editor with updated configuration
    const editor = useEditor({
      extensions: [StarterKit],
      content: documents[activeTab].content,
      onUpdate: ({ editor }) => {
        const newContent = editor.getHTML();
        // Only update if content has actually changed
        if (newContent !== lastSavedContent) {
          handleContentChange(activeTab, newContent);
          collaborationService.broadcastChange(activeTab, {
            type: 'content',
            content: newContent
          });
        }
      },
      editorProps: {
        attributes: {
          class: 'prose max-w-none min-h-[842px] p-4 focus:outline-none', // A4 page height in pixels
        },
      },
    });
  
    // Update editor content when switching tabs
    useEffect(() => {
      if (editor) {
        editor.commands.setContent(documents[activeTab].content);
        setLastSavedContent(documents[activeTab].content);
      }
    }, [activeTab, documents, editor]);
  
    // Load all documents on mount with correct initial titles
    useEffect(() => {
      const loadAllDocuments = async () => {
        try {
          const loadedDocs = {};
          for (const tabId in documents) {
            const doc = await documentService.loadDocument(tabId);
            loadedDocs[tabId] = {
              ...documents[tabId], // Keep the initial title
              content: doc.content || '',
              lastSaved: doc.lastSaved || new Date(),
              permissions: doc.permissions || {}
            };
          }
          setDocuments(loadedDocs);
        } catch (err) {
          setError('Failed to load documents');
        }
      };
  
      loadAllDocuments();
    }, []);
  
    // Debounced save function with optimized updates
    const saveDocument = useCallback(async (tabId, changes) => {
      try {
        setIsSaving(true);
        const updatedDoc = {
          ...documents[tabId],
          ...changes,
          lastSaved: new Date()
        };
        
        await documentService.saveDocument(tabId, updatedDoc);
        setDocuments(prev => ({
          ...prev,
          [tabId]: updatedDoc
        }));
        
        if (changes.content) {
          setLastSavedContent(changes.content);
        }
        
        setError(null);
      } catch (err) {
        setError('Failed to save changes');
      } finally {
        setIsSaving(false);
      }
    }, [documents]);
  
    // Optimized content change handler with debouncing
    const handleContentChange = useCallback((tabId, newContent) => {
      setDocuments(prev => ({
        ...prev,
        [tabId]: {
          ...prev[tabId],
          content: newContent
        }
      }));
      
      // Debounced save
      const timeoutId = setTimeout(() => {
        if (newContent !== lastSavedContent) {
          saveDocument(tabId, { content: newContent });
        }
      }, 4000); // Increased debounce time to 2 seconds
  
      return () => clearTimeout(timeoutId);
    }, [lastSavedContent, saveDocument]);
  
    // Updated title editing handlers for seamless editing
    const startTitleEdit = (tabId) => {
      setEditingTitleValue(documents[tabId].title);
      setIsEditingTitle(true);
    };
  
    const handleTitleEdit = (tabId, newTitle) => {
      saveDocument(tabId, { title: newTitle });
      setIsEditingTitle(false);
    };

  // Permission management
  const updatePermissions = (tabId, newPermissions) => {
    saveDocument(tabId, {
      permissions: {
        ...documents[tabId].permissions,
        ...newPermissions
      }
    });
  };

  const ShareDialog = () => (
    <Modal
      isOpen={showShareDialog}
      onClose={() => setShowShareDialog(false)}
      title="Share Document"
    >
      <div className="space-y-4">
        <div className="flex items-center space-x-2">
          <Users className="h-5 w-5" />
          <input
            type="email"
            placeholder="Add people by email"
            className="flex-1 p-2 border rounded"
          />
        </div>
        <div className="space-y-2">
          <h4 className="font-medium">People with access</h4>
          {documents[activeTab].permissions.editors?.map(editor => (
            <div key={editor} className="flex items-center justify-between">
              <span>{editor}</span>
              <select
                className="border rounded p-1"
                onChange={(e) => updatePermissions(activeTab, {
                  [e.target.value]: [...documents[activeTab].permissions[e.target.value], editor]
                })}
              >
                <option value="editors">Can edit</option>
                <option value="readers">Can view</option>
              </select>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );

  return (
    <div className="mt-12">
      <Header />
      <div className="container mx-auto p-6">
        <div className="bg-white rounded-lg shadow-lg p-6">
          {/* Document Header */}
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center space-x-4">
              {isEditingTitle ? (
                <input
                    type="text"
                    value={editingTitleValue}
                    onChange={(e) => setEditingTitleValue(e.target.value)}
                    onBlur={() => handleTitleEdit(activeTab, editingTitleValue)}
                    onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleTitleEdit(activeTab, editingTitleValue);
                    }
                    }}
                    className="border rounded px-2 py-1"
                    autoFocus
                />
              ) : (
                <h2 className="text-xl font-semibold flex items-center">
                  {documents[activeTab].title}
                  <button
                    onClick={() => startTitleEdit(activeTab)}
                    className="ml-2 text-gray-400 hover:text-gray-600"
                  >
                    <Edit2 className="h-4 w-4" />
                  </button>
                </h2>
              )}
            </div>
            
            <div className="flex items-center space-x-4">
              {/* Active Users */}
              <div className="flex -space-x-2">
                {Array.from(activeUsers).map((user, i) => (
                  <div
                    key={user}
                    className="h-8 w-8 rounded-full bg-blue-500 flex items-center justify-center text-white text-sm border-2 border-white"
                  >
                    {user[0].toUpperCase()}
                  </div>
                ))}
              </div>

              {/* Share Button */}
              <button
                onClick={() => setShowShareDialog(true)}
                className="flex items-center space-x-2 px-4 py-2 border rounded-lg hover:bg-gray-50"
              >
                <Share2 className="h-4 w-4" />
                <span>Share</span>
              </button>
            </div>
          </div>

          {/* Tabs Navigation - Now properly displayed */}
          <div className="border-b border-gray-200 mb-4">
            <div className="flex -mb-px">
              {Object.entries(documents).map(([tabId, doc]) => (
                <button
                  key={tabId}
                  onClick={() => setActiveTab(tabId)}
                  className={`py-2 px-4 text-sm font-medium mr-2 focus:outline-none transition-colors
                    ${activeTab === tabId
                      ? 'border-b-2 border-blue-500 text-blue-600'
                      : 'text-gray-500 hover:text-gray-700 hover:border-gray-300'
                    }`}
                >
                  {doc.title}
                </button>
              ))}
            </div>
          </div>

          {/* Status Bar */}
          <div className="flex justify-between mb-4">
            <div className="text-sm text-gray-500">
              {documents[activeTab].lastSaved && 
                `Last saved: ${new Date(documents[activeTab].lastSaved).toLocaleTimeString()}`
              }
            </div>
            <div className="flex items-center text-sm">
              {error ? (
                <div className="text-red-500 bg-red-50 px-4 py-2 rounded">
                  {error}
                </div>
              ) : (
                <div className="flex items-center text-gray-500">
                  <div className={`mr-2 h-2 w-2 rounded-full ${isSaving ? 'bg-yellow-400' : 'bg-green-400'}`}></div>
                  {isSaving ? 'Saving...' : 'All changes saved'}
                </div>
              )}
            </div>
          </div>

          {/* Rich Text Editor with improved sizing */}
          <div className="border rounded-lg">
            <div className="border-b p-2">
              <button
                onClick={() => editor?.chain().focus().toggleBold().run()}
                className={`p-2 rounded mr-2 ${editor?.isActive('bold') ? 'bg-gray-200' : ''}`}
              >
                Bold
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleItalic().run()}
                className={`p-2 rounded ${editor?.isActive('italic') ? 'bg-gray-200' : ''}`}
              >
                Italic
              </button>
            </div>
            
            <EditorContent editor={editor} className="w-full" />
          </div>
        </div>
      </div>

      <ShareDialog />
    </div>
  );
};

export default DepartmentNotes;