import Header from "./Header";

const HR = () => {
    const [] = '';

    return (
      <div>
        <Header />
        <h1 className="pt-20 flex justify-center underline">HR PORTAL</h1>
        <h1>Test: </h1>
      </div>
    );
  };
  
  export default HR;