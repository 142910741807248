import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const EmployeeProfileTabs = () => {
  const [activeTab, setActiveTab] = useState('onboarding');
  const [onboardingChecklist, setOnboardingChecklist] = useState([
    { id: 1, label: 'Term Sheet Sent', checked: false },
    { id: 2, label: 'Welcome Email + Demographic Paperwork Sent', checked: false },
    { id: 3, label: 'Google Workspace Created', checked: false },
    { id: 4, label: 'Hellosign Received and Uploaded', checked: false },
    { id: 5, label: 'Bio Received', checked: false },
    { id: 6, label: 'Special Interests Received', checked: false },
    { id: 7, label: 'Added to Google Chat Groups', checked: false },
    { id: 8, label: 'Added to Contact Sheet', checked: false },
    { id: 9, label: 'Added to Organization Chart', checked: false },
    { id: 10, label: 'Driver\'s License Received', checked: false },
    { id: 11, label: 'License Received', checked: false },
    { id: 12, label: 'NPI', checked: false },
    { id: 13, label: 'Orientation Complete', checked: false },
    { id: 14, label: 'Start Up Materials Check Provided', checked: false },
    { id: 15, label: 'Headshot Taken', checked: false },
  ]);
  const [employmentStatus, setEmploymentStatus] = useState('');
  const [service, setService] = useState('');
  const [licenseNum, setLicenseNum] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [continuingEducationRows, setContinuingEducationRows] = useState([]);
  const [ceuMax, setCEUMax] = useState(0);
  const [ceuRemaining, setCEURemaining] = useState(0);
  const [wellnessHoursRows, setWellnessHoursRows] = useState([]);
  const [maxPTO, setMaxPTO] = useState(0);
  const [ptoRemaining, setPTORemaining] = useState(0);
  const [specialPTO, setSpecialPTO] = useState(0);
  const [approvedUnpaid, setApprovedUnpaid] = useState(0);

  useEffect(() => {
    // Retrieve employment status and service from localStorage on component mount
    const storedStatus = localStorage.getItem('employmentStatus');
    const storedService = localStorage.getItem('service');
    const storedLicenseNum = localStorage.getItem('licenseNum');
    const storedDateOfBirth = localStorage.getItem('dateOfBirth');
    const storedCEUMax = localStorage.getItem('ceuMax')
    const storedMaxPTO = localStorage.getItem('maxPTO')
    const storedSpecialPTO = localStorage.getItem('specialPTO')
    const storedApprovedUnpaid = localStorage.getItem('approvedUnpaid')
    if (storedStatus !== null) {
      setEmploymentStatus(storedStatus);
    }
    if (storedService !== null) {
      setService(storedService);
    }
    if (storedLicenseNum !== null) {
        setLicenseNum(storedLicenseNum);
    }
    if (storedDateOfBirth !== null) {
        setDateOfBirth(new Date(storedDateOfBirth));
    }
    if (storedCEUMax !== null) {
        setCEUMax(parseFloat(storedCEUMax));
    }
    if (storedMaxPTO !== null) {
        setMaxPTO(parseFloat(storedMaxPTO));
    }
    if (storedSpecialPTO !== null) {
        setSpecialPTO(parseFloat(storedSpecialPTO));
    }
    if (storedApprovedUnpaid !== null) {
        setApprovedUnpaid(parseFloat(storedApprovedUnpaid));
    }
  }, []);

  useEffect(() => {
    const totalAmount = continuingEducationRows.reduce((sum, row) => sum + row.amount, 0);
    setCEURemaining(ceuMax - totalAmount);
  }, [continuingEducationRows, ceuMax]);

  useEffect(() => {
    const totalPTO = wellnessHoursRows.reduce((sum, row) => sum + row.approvedPTO, 0);
    setPTORemaining(maxPTO - totalPTO);
  }, [wellnessHoursRows, maxPTO]);

  const handleAddEducationRow = () => {
    setContinuingEducationRows((prevRows) => [
      ...prevRows,
      {
        id: prevRows.length + 1,
        dateRequested: '',
        ceuCourseName: '',
        amount: '',
        status: '',
        dateReimbursed: '',
      },
    ]);
  };
  const handleAddWellnessRow = () => {
    setWellnessHoursRows((prevRows) => [
      ...prevRows,
      {
        id: prevRows.length + 1,
        dateRequested: '',
        hoursRequested: '',
        approvedPTO: '',
        specialPTO: '',
        approvedUnpaid: '',
      },
    ]);
  };

  const handleRowChange = (rowIndex, columnName, value) => {
    setContinuingEducationRows((prevRows) =>
      prevRows.map((row, index) =>
        index === rowIndex
          ? { ...row, [columnName]: value }
          : row
      )
    );
  };
  const handleWellnessRowChange = (rowIndex, columnName, value) => {
    setWellnessHoursRows((prevRows) =>
      prevRows.map((row, index) =>
        index === rowIndex
          ? { ...row, [columnName]: value }
          : row
      )
    );
  };

  const handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    setEmploymentStatus(selectedStatus);
    // Store the selected status in localStorage
    localStorage.setItem('employmentStatus', selectedStatus);
  };

  const handleServiceChange = (event) => {
    const selectedService = event.target.value;
    setService(selectedService);
    // Store the selected service in localStorage
    localStorage.setItem('service', selectedService);
  };

  const handleLicenseChange = (event) => {
    const selectedLicenseNum = event.target.value;
    setLicenseNum(selectedLicenseNum);
    // Store the selected service in localStorage
    localStorage.setItem('licenseNum', selectedLicenseNum);
  };

  const handleDateOfBirthChange = (date) => {
    setDateOfBirth(date);
    // Store the date of birth in localStorage
    localStorage.setItem('dateOfBirth', date.toISOString());
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleCEUMaxChange = (event) => {
    const selectedCEUMax = parseFloat(event.target.value);
    setCEUMax(selectedCEUMax);
    localStorage.setItem('ceuMax', selectedCEUMax.toString());
  };
  const handleMaxPTOChange = (event) => {
    const selectedMaxPTO = parseFloat(event.target.value);
    setMaxPTO(selectedMaxPTO);
    localStorage.setItem('maxPTO', selectedMaxPTO.toString());
  };
  const handleSpecialPTOChange = (event) => {
    const selectedSpecialPTO = parseFloat(event.target.value);
    setSpecialPTO(selectedSpecialPTO);
    localStorage.setItem('specialPTO', selectedSpecialPTO.toString());
  };
  const handleApprovedUnpaidChange = (event) => {
    const selectedApprovedUnpaid = parseFloat(event.target.value);
    setApprovedUnpaid(selectedApprovedUnpaid);
    localStorage.setItem('approvedUnpaid', selectedApprovedUnpaid.toString());
  };

  const documents = [
    { id: 1, name: 'Photo Consent', url: '#' },
    { id: 2, name: 'Insurance', url: '#' },
    { id: 3, name: 'Document 3', url: '#' },
  ];

  const handleCheckboxChange = (id) => {
    setOnboardingChecklist((prevChecklist) =>
      prevChecklist.map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item
      )
    );
  };

  return (
    <div className="bg-white rounded-lg shadow-md h-full">
      <div className="flex justify-center border-b border-gray-200">
        <button
          className={`px-4 py-2 font-medium text-sm ${
            activeTab === 'onboarding'
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => handleTabClick('onboarding')}
        >
          Onboarding
        </button>
        <button
          className={`px-4 py-2 font-medium text-sm ${
            activeTab === 'demographics'
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => handleTabClick('demographics')}
        >
          Demographics
        </button>
        <button
          className={`px-4 py-2 font-medium text-sm ${
            activeTab === 'payroll'
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => handleTabClick('payroll')}
        >
          Payroll
        </button>
        <button
          className={`px-4 py-2 font-medium text-sm ${
            activeTab === 'continuing-education'
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => handleTabClick('continuing-education')}
        >
          Continuing Education
        </button>
        <button
          className={`px-4 py-2 font-medium text-sm ${
            activeTab === 'wellness-hours'
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => handleTabClick('wellness-hours')}
        >
          Wellness Hours
        </button>
        <button
          className={`px-4 py-2 font-medium text-sm ${
            activeTab === 'payroll-detail'
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => handleTabClick('payroll-detail')}
        >
          Payroll Detail
        </button>
        <button
          className={`px-4 py-2 font-medium text-sm ${
            activeTab === 'documents'
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => handleTabClick('documents')}
        >
          Documents
        </button>
      </div>
      <div className="p-6">
        {activeTab === 'onboarding' && (
          <div>
          <h2 className="text-lg font-medium mb-4">Onboarding Checklist</h2>
          <ul className="space-y-2">
            {onboardingChecklist.map((item) => (
              <li key={item.id} className="flex items-center">
                <input
                  type="checkbox"
                  checked={item.checked}
                  onChange={() => handleCheckboxChange(item.id)}
                  className="mr-2 h-4 w-4 text-blue-600 focus:ring-blue-500"
                />
                <label className={`${item.checked ? 'line-through text-gray-400' : ''}`}>
                  {item.label}
                </label>
              </li>
            ))}
          </ul>
        </div>
        )}
        {activeTab === 'demographics' && (
          <div>
            <h2 className="text-lg font-medium mb-4 underline">Demographics</h2>
            <div className="mb-4">
                <label htmlFor="dob" className="block font-medium mb-2">
                    Date of Birth:
                </label>
                <DatePicker
                    id="dob"
                    selected={dateOfBirth}
                    onChange={(date) => handleDateOfBirthChange(date)}
                    className="border rounded px-3 py-2 w-full"
                    placeholderText="Select a date"
                    showYearDropdown
                    scrollableYearDropdown
                    yearDropdownItemNumber={100}
                />
                </div>
            <h2 className="text-lg font-medium mb-4 underline">Clinical</h2>
            <div className="mb-4">
            <label htmlFor="status" className="block font-medium mb-2">
              Status:
            </label>
            <select
              id="status"
              value={employmentStatus}
              onChange={handleStatusChange}
              className="border rounded px-3 py-2 w-full"
            >
              <option value="">Select status</option>
              <option value="full-time">Full-time</option>
              <option value="part-time">Part-time</option>
            </select>
          </div>
          <div className="mb-4">
            <label htmlFor="service" className="block font-medium mb-2">
              Service:
            </label>
            <select
              id="service"
              value={service}
              onChange={handleServiceChange}
              className="border rounded px-3 py-2 w-full"
            >
              <option value="">Select service</option>
              <option value="st">ST</option>
              <option value="ot">OT</option>
              <option value="administration">Administration</option>
            </select>
          </div>
          <div className="mb-4">
                <label htmlFor="license" className="block font-medium mb-2">
                  License #:
                </label>
                <input
                  id="license"
                  type="text"
                  value={licenseNum}
                  onChange={handleLicenseChange}
                  className="border rounded px-3 py-2 w-full"
                />
              </div>
          </div>
        )}
        {activeTab === 'payroll' && (
          <div>
            <h2 className="text-lg font-medium mb-4 underline">Payroll - General</h2>
            {/* Add your payroll table content here */}
            <p>2024 Term Salary: ___________</p>
            <p>Start Date: ___________</p>
            <p>End Date: ___________</p>
            <p>Biweekly Rate: ___________</p>
            <div className="mb-2 mt-2 flex items-center">
                <label htmlFor="yesNo" className="">
                Health Insurance Stipend:
                </label>
                <select
                    id="yesNo"
                    className="border rounded px-3 py-2 w-full max-w-[200px]"
                >
                    <option value="">Select an option</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </select>
            </div>
            <p>Health Insurance Rate: ___________/Monthly</p>
            <p>Continuing Education Balance: ___________/1000</p>
            <p>Wellness Hours: ___________/# Hrs</p>
            <div className="mt-2 flex items-center">
                <label htmlFor="checkboxButton" className="">
                    Floating Day:
                </label>
                <input
                    type="checkbox"
                    id="checkboxButton"
                    className="mr-2 h-4 w-4 text-blue-600 focus:ring-blue-500"
                />
            </div>
          </div>
        )}
        {activeTab === 'documents' && (
          <div>
            <h2 className="text-lg font-medium mb-4">Documents</h2>
            {/* Add your continuing-education table content here */}
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left px-4 py-2">Name</th>
                  <th className="text-left px-4 py-2">Actions</th>
                </tr>
              </thead>
              <tbody>
                {documents.map((document) => (
                  <tr key={document.id}>
                    <td className="px-4 py-2">{document.name}</td>
                    <td className="px-4 py-2">
                      <a
                        href={document.url}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        View
                      </a>
                      <button className="ml-4 text-blue-500 hover:text-blue-700">
                        Download
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {activeTab === 'wellness-hours' && (
          <div>
          <h2 className="text-lg font-medium mb-4">Wellness Hours</h2>
          <div className="flex justify-center">
            <div className="mb-4 flex items-center">
                <label htmlFor="maxPTO" className="font-medium mr-4">
                    MAX PTO:
                </label>
                <input
                    id="maxPTO"
                    type="number"
                    value={maxPTO}
                    onChange={handleMaxPTOChange}
                    className="border rounded px-3 py-2 w-full max-w-[200px]"
                />
                </div>
                <div className="mb-4 ml-8 flex items-center">
                <label htmlFor="ptoRemaining" className="font-medium mr-4">
                    PTO REMAINING:
                </label>
                <input
                    id="ptoRemaining"
                    type="number"
                    value={ptoRemaining}
                    readOnly
                    className="border rounded px-3 py-2 w-full max-w-[200px]"
                />
                </div>
                <div className="mb-4 ml-4 flex items-center">
                <label htmlFor="maxPTO" className="font-medium mr-4">
                    SPECIAL PTO:
                </label>
                <input
                    id="specialPTO"
                    type="number"
                    value={specialPTO}
                    onChange={handleSpecialPTOChange}
                    className="border rounded px-3 py-2 w-full max-w-[200px]"
                />
                </div>
                <div className="mb-4 ml-4 flex items-center">
                <label htmlFor="maxPTO" className="font-medium mr-4">
                    APPROVED UNPAID:
                </label>
                <input
                    id="approvedUnpaid"
                    type="number"
                    value={approvedUnpaid}
                    onChange={handleApprovedUnpaidChange}
                    className="border rounded px-3 py-2 w-full max-w-[200px]"
                />
                </div>
            </div>
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border-b px-4 py-2">Date Requested</th>
                <th className="border-b px-4 py-2">Hours Requested</th>
                <th className="border-b px-4 py-2">Approved PTO</th>
                <th className="border-b px-4 py-2">Approved Unpaid</th>
                <th className="border-b px-4 py-2">Notes</th>
              </tr>
            </thead>
            <tbody>
              {wellnessHoursRows.map((row, index) => (
                <tr key={index}>
                  <td className="border-b px-4 py-2">
                    <input
                      type="text"
                      value={row.dateRequested}
                      onChange={(e) => handleWellnessRowChange(index, 'dateRequested', e.target.value)}
                      className="border rounded px-3 py-2 w-full"
                    />
                  </td>
                  <td className="border-b px-4 py-2">
                    <input
                      type="text"
                      value={row.hoursRequested}
                      onChange={(e) => handleWellnessRowChange(index, 'hoursRequested', e.target.value)}
                      className="border rounded px-3 py-2 w-full"
                    />
                  </td>
                  <td className="border-b px-4 py-2">
                    <input
                      type="text"
                      value={row.approvedPTO}
                      onChange={(e) => handleWellnessRowChange(index, 'approvedPTO', e.target.value)}
                      className="border rounded px-3 py-2 w-full"
                    />
                  </td>
                  <td className="border-b px-4 py-2">
                    <input
                      type="text"
                      value={row.approvedUnpaid}
                      onChange={(e) => handleWellnessRowChange(index, 'approvedUnpaid', e.target.value)}
                      className="border rounded px-3 py-2 w-full"
                    />
                  </td>
                  <td className="border-b px-4 py-2">
                    <input
                      type="text"
                      value={row.notes}
                      onChange={(e) => handleWellnessRowChange(index, 'notes', e.target.value)}
                      className="border rounded px-3 py-2 w-full"
                    />
                  </td>
                  <td className="border-b px-4 py-2">
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            className="mt-4 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
            onClick={handleAddWellnessRow}
          >
            Add
          </button>
        </div>
        )}
        {activeTab === 'payroll-detail' && (
          <div>
            <h2 className="text-lg font-medium mb-4">Payroll Detail</h2>
            {/* Add your demographics table content here */}
          </div>
        )}
        {activeTab === 'continuing-education' && (
          <div>
          <h2 className="text-lg font-medium mb-4">Continuing Education</h2>
          <div className="flex justify-center">
            <div className="mb-4 flex items-center">
                <label htmlFor="ceuMax" className="font-medium mr-4">
                    CEU MAX:
                </label>
                <input
                    id="ceuMax"
                    type="number"
                    value={ceuMax}
                    onChange={handleCEUMaxChange}
                    className="border rounded px-3 py-2 w-full max-w-[200px]"
                />
                </div>
                <div className="mb-4 ml-8 flex items-center">
                <label htmlFor="ceuRemaining" className="font-medium mr-4">
                    CEU REMAINING:
                </label>
                <input
                    id="ceuRemaining"
                    type="number"
                    value={ceuRemaining}
                    readOnly
                    className="border rounded px-3 py-2 w-full max-w-[200px]"
                />
                </div>
            </div>
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border-b px-4 py-2">Date Requested</th>
                <th className="border-b px-4 py-2">CEU Course Name</th>
                <th className="border-b px-4 py-2">Amount</th>
                <th className="border-b px-4 py-2">Status</th>
                <th className="border-b px-4 py-2">Date Reimbursed</th>
              </tr>
            </thead>
            <tbody>
              {continuingEducationRows.map((row, index) => (
                <tr key={index}>
                  <td className="border-b px-4 py-2">
                    <input
                      type="text"
                      value={row.dateRequested}
                      onChange={(e) => handleRowChange(index, 'dateRequested', e.target.value)}
                      className="border rounded px-3 py-2 w-full"
                    />
                  </td>
                  <td className="border-b px-4 py-2">
                    <input
                      type="text"
                      value={row.ceuCourseName}
                      onChange={(e) => handleRowChange(index, 'ceuCourseName', e.target.value)}
                      className="border rounded px-3 py-2 w-full"
                    />
                  </td>
                  <td className="border-b px-4 py-2">
                    <input
                      type="text"
                      value={row.amount}
                      onChange={(e) => handleRowChange(index, 'amount', parseFloat(e.target.value))}
                      className="border rounded px-3 py-2 w-full"
                    />
                  </td>
                  <td className="border-b px-4 py-2">
                    <select
                        value={row.status}
                        onChange={(e) => handleRowChange(index, 'status', e.target.value)}
                        className="border rounded px-3 py-2 w-full"
                    >
                        <option value="">Select status</option>
                        <option value="requested">Requested</option>
                        <option value="approved">Approved</option>
                        <option value="denied">Denied</option>
                    </select>
                </td>
                  <td className="border-b px-4 py-2">
                    <input
                      type="text"
                      value={row.dateReimbursed}
                      onChange={(e) => handleRowChange(index, 'dateReimbursed', e.target.value)}
                      className="border rounded px-3 py-2 w-full"
                    />
                  </td>
                  <td className="border-b px-4 py-2">
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            className="mt-4 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
            onClick={handleAddEducationRow}
          >
            Add
          </button>
        </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeProfileTabs;