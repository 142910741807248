import { format, addDays, subDays, addMonths, subMonths } from 'date-fns';
import { startOfMonth, endOfMonth, startOfWeek, endOfWeek, eachDayOfInterval, isSameDay, isSameMonth } from 'date-fns';
import { React, useState } from 'react';
import {
    ChevronLeftIcon,
    ChevronRightIcon
  } from '@heroicons/react/24/solid';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }


const Calendar = ({ onDateSelect }) => {

    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(new Date());

    const updateCurrentDate = (date) => {
        setSelectedDate(date);
        onDateSelect(date);
      };

    const renderMini = () => {
        const monthStart = startOfMonth(selectedDate);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart, { weekStartsOn: 1 }); // Start on Monday
        const endDate = endOfWeek(monthEnd, { weekStartsOn: 1 });
      
        const rows = [];
        let days = [];
        let day = startDate;
        const today = new Date();
      
        while (day <= endDate) {
          for (let i = 0; i < 7; i++) {
            const cloneDay = day;
            days.push(
              <div
                key={day.toString()}
                className={classNames(
                  'py-1.5',
                  !isSameMonth(day, monthStart) && 'bg-gray-50 text-gray-400',
                  isSameMonth(day, monthStart) && 'bg-white',
                  isSameDay(day, today) && 'text-indigo-600',
                  isSameDay(day, selectedDate) && 'font-semibold'
                )}
              >
                <button
                  onClick={() => updateCurrentDate(cloneDay)}
                  className="w-full h-full flex items-center justify-center"
                >
                  <time
                    dateTime={format(day, 'yyyy-MM-dd')}
                    className={classNames(
                      'flex h-7 w-7 items-center justify-center rounded-full',
                      isSameDay(day, selectedDate) && 'bg-blue-700 text-white'
                    )}
                  >
                    {format(day, 'd')}
                  </time>
                </button>
              </div>
            );
            day = addDays(day, 1);
          }
          rows.push(
            <div key={day.toString()} className="grid grid-cols-7 gap-px">
              {days}
            </div>
          );
          days = [];
        }
      
        return (
          <div className="bg-gray-200 p-px rounded-lg shadow ring-1 ring-gray-200">
            {rows}
          </div>
        );
      };
    
    return (
      <div>
        <div className="flex items-center justify-center pt-8 px-4">
            <div className="bg-gray-100 max-w-sm w-full shadow-xl">
            <h1 className="text-md text-center font-semibold text-gray-900">
                <time className="hidden sm:inline">
                Today is : {format(currentDate, 'MMMM d, yyyy')}
                </time>
            </h1>
            <h1 className="text-md -mt-8 text-center font-semibold text-gray-900">
                <time className="hidden sm:inline">
                You are viewing : {format(selectedDate, 'MMMM d, yyyy')}
                </time>
            </h1>
            <div className="hidden max-w-md flex-none border-l border-gray-100 pb-10 px-8 md:block">
                <div className="flex items-center text-center text-gray-900">
                    <button
                    type="button"
                    onClick={() => setSelectedDate(subMonths(selectedDate, 1))}
                    className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                    >
                    <span className="sr-only">Previous month</span>
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <div className="flex-auto font-semibold">{format(selectedDate, 'MMMM yyyy')}</div>
                    <button
                    type="button"
                    onClick={() => setSelectedDate(addMonths(selectedDate, 1))}
                    className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                    >
                    <span className="sr-only">Next month</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                </div>
                <div className="mt-6 grid grid-cols-7 text-center text-xs leading-6 text-gray-500">
                    <div>M</div>
                    <div>T</div>
                    <div>W</div>
                    <div>T</div>
                    <div>F</div>
                    <div>S</div>
                    <div>S</div>
                </div>
                {renderMini()}
            </div>
                <div className="md:py-8 py-5 md:px-16 px-5 dark:bg-gray-700 bg-gray-50 rounded-b">
                    <div className="px-4">
                        <div className="border-b pb-4 border-gray-400 border-dashed">
                            <p className="text-xs font-light leading-3 text-gray-500 dark:text-gray-300">9:00 AM</p>
                            <a tabindex="0" className="focus:outline-none text-lg font-medium leading-5 text-gray-800 dark:text-gray-100 mt-2">Zoom call with design team</a>
                            <p className="text-sm pt-2 leading-4 leading-none text-gray-600 dark:text-gray-300">Discussion on UX sprint and Wireframe review</p>
                        </div>
                        <div className="border-b pb-4 border-gray-400 border-dashed pt-5">
                            <p className="text-xs font-light leading-3 text-gray-500 dark:text-gray-300">10:00 AM</p>
                            <a tabindex="0" className="focus:outline-none text-lg font-medium leading-5 text-gray-800 dark:text-gray-100 mt-2">Orientation session with new hires</a>
                        </div>
                        <div className="border-b pb-4 border-gray-400 border-dashed pt-5">
                            <p className="text-xs font-light leading-3 text-gray-500 dark:text-gray-300">9:00 AM</p>
                            <a href="https://google.com" tabindex="0" className="focus:outline-none text-lg font-medium leading-5 text-gray-800 dark:text-gray-100 mt-2">Zoom call with design team</a>
                            <p className="text-sm pt-2 leading-4 leading-none text-gray-600 dark:text-gray-300">Discussion on UX sprint and Wireframe review</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  };
  
export default Calendar;