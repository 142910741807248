import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { format, addMonths } from 'date-fns';
import { ca } from 'date-fns/locale';
import { PrintButton, ScheduleTranscript } from './ScheduleTranscript';

const ScheduleTab = () => {
  const { state } = useLocation();
  const { patient } = state;
  const [events, setEvents] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addMonths(new Date(), 1));
  const patientID = patient.PatientID

  const sortEventsByDate = (events) => {
    return events.sort((a, b) => {
      const dateA = new Date(a.start_time);
      const dateB = new Date(b.start_time);
      return dateA - dateB;
    });
  };

  const parseDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date;
  };


  const callLambdaFunction = async () => {
    const formatStart = format(startDate, 'yyyy-MM-dd');
    const formatEnd = format(endDate, 'yyyy-MM-dd');
    const payload = {
      patient_id: patientID,
      start_date: formatStart,
      end_date: formatEnd
    };
  
    try {
      const response = await fetch('https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/get_patient_events', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('Lambda function response:', data);
      
      // Parse JSON string (data) into an array
      const parsedData = JSON.parse(data);
      console.log('Parsed data: ', parsedData);
  
      setEvents(parsedData);
    } catch (error) {
      console.error('Error calling Lambda function:', error);
    }
  };

  const changeEventStatus = async (uniqueEventId, excepDate, cancelReason, excStatus) => {
    const [eventId, eventSchId, formattedDate] = uniqueEventId.split('-');
    
    const payload = {
      event_id: eventId,
      event_schedule_id: eventSchId,
      excep_date: excepDate,
      cancel_reason: cancelReason,
      exc_status: excStatus,
    };
  
    try {
      const response = await fetch(
        'https://39vhe7wbe3.execute-api.us-east-1.amazonaws.com/Testing/sstudio/create_exception_event',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log('create_exception_event response:', data);
  
      const updatedEvent = {
        event_id: parseInt(eventId),
        event_schedule_id: parseInt(eventSchId),
        ev_status: excStatus,
        cancel_reason: cancelReason,
        start_time: excepDate
      };
  
      console.log('Returning updated event:', updatedEvent);
      return updatedEvent;
  
    } catch (error) {
      console.error('Error updating event status:', error);
      throw error;
    }
  };

  const handleStartDateChange = (e) => {
    setStartDate(new Date(e.target.value));
  };
  const handleEndDateChange = (e) => {
    setEndDate(new Date(e.target.value));
  };

  const handleStatusChange = useCallback(async (e, uniqueEventId, startTime) => {
    const newStatus = e.target.value;
    console.log('New status selected:', newStatus, 'for event on', format(new Date(startTime), 'yyyy-MM-dd'));
  
    let excStatus;
    let cancelReason = '';
    switch (newStatus) {
      case 'Canceled by Patient':
      case 'Canceled by Provider':
        excStatus = 'C';
        cancelReason = newStatus;
        break;
      case 'Scheduled':
        excStatus = 'A';
        break;
      case 'No Show':
        excStatus = 'N';
        break;
      case 'Confirmed':
        excStatus = 'Y';
        break;
      default:
        excStatus = 'A'; // Default to Scheduled
    }
  
    const excepDate = format(new Date(startTime), 'yyyy-MM-dd');
  
    // Optimistic update
    setEvents(prevEvents => prevEvents.map(evt => {
      if (`${evt.event_id}-${evt.event_schedule_id}-${format(new Date(evt.start_time), 'yyyy-MM-dd')}` === uniqueEventId) {
        return { ...evt, ev_status: excStatus, cancel_reason: cancelReason };
      }
      return evt;
    }));
  
    try {
      const updatedEvent = await changeEventStatus(uniqueEventId, excepDate, cancelReason, excStatus);
      console.log('Updated event:', updatedEvent);
  
      // Confirm update with server response
      setEvents(prevEvents => prevEvents.map(evt => {
        if (`${evt.event_id}-${evt.event_schedule_id}-${format(new Date(evt.start_time), 'yyyy-MM-dd')}` === uniqueEventId) {
          return { ...updatedEvent, start_time: evt.start_time };
        }
        return evt;
      }));
  
      // Fetch fresh data after successful update
      await callLambdaFunction();
    } catch (error) {
      console.error('Failed to update event status:', error);
      // Revert on error
      setEvents(prevEvents => prevEvents.map(evt => {
        if (`${evt.event_id}-${evt.event_schedule_id}-${format(new Date(evt.start_time), 'yyyy-MM-dd')}` === uniqueEventId) {
          return { ...evt, ev_status: evt.originalStatus, cancel_reason: evt.originalCancelReason };
        }
        return evt;
      }));
    }
  }, [changeEventStatus, callLambdaFunction]);

  const getEventStatus = (event) => {
    console.log('Getting status for event:', event.event_id, 'on', format(new Date(event.start_time), 'yyyy-MM-dd'), 'Status:', event.ev_status, 'Cancel Reason:', event.cancel_reason);
    if (event.ev_status === 'A') return 'Scheduled';
    if (event.ev_status === 'Y') return 'Confirmed';
    if (event.ev_status === 'C') return event.cancel_reason || 'Canceled';
    if (event.ev_status === 'N') return 'No Show';
    return 'Scheduled'; // Default value
  };

  const getEventClass = (event) => {
    const formattedDate = format(new Date(event.start_time), 'yyyy-MM-dd');
    const uniqueEventId = `${event.event_id}-${event.event_schedule_id}-${formattedDate}`;
    const updatedEvent = events.find(e => 
      `${e.event_id}-${e.event_schedule_id}-${format(new Date(e.start_time), 'yyyy-MM-dd')}` === uniqueEventId
    );
    
    return updatedEvent && (updatedEvent.ev_status === 'C' || updatedEvent.ev_status === 'N') 
      ? 'bg-red-50' 
      : 'bg-blue-50';
  };

  const documentData = {
    service: "Speech Therapy",
    provider: "Miss Alexandra",
    day: "Monday / Thursday",
    time: "3:30pm - 4:00pm",
    location: "225 Richmond Hill Road, SI, NY, 10314",
    startingDate: "Monday, October 14th",
    deductible: "Met",
    copay: "$10",
    intake: "$90",
    patient: "Max Livit",
  };

  useEffect(() => {
    callLambdaFunction();
  }, []);

  useEffect(() => {
    callLambdaFunction();
  }, [startDate, endDate]);

  return (
    <div>
      <div className="flex">
        <h1>You are viewing events for <span className="font-bold">{patient.FirstName}</span> from: </h1>
        <div className="mx-2 mt-2">
          <input type="date" id="start_date" value={format(startDate, 'yyyy-MM-dd')} onChange={handleStartDateChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="2024-07-01" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
        </div>
        <h1>To:</h1>
        <div className="ml-2 mt-2">
          <input type="date" id="end_date" value={format(endDate, 'yyyy-MM-dd')} onChange={handleEndDateChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="2024-07-01" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" required />
        </div>
        <button
            onClick={callLambdaFunction}
            className="ml-4 mt-3 bg-blue-500 hover:bg-blue-700 text-white font-bold px-4 rounded h-[2rem]"
          >
            Get Events
        </button>
        <div className="p-4">
          <div className="max-w-3xl mx-auto">
            <div className="mb-4">
              <PrintButton documentData={documentData} />
            </div>
            <ScheduleTranscript data={documentData} />
          </div>
        </div>
      </div>
      <div className="bg-gray-200 h-screen rounded-xl p-2">
  <div className="bg-white h-[100%] shadow-lg rounded-lg max-h-screen p-6 flex-grow overflow-y-auto">
    {events.length === 0 ? (
      <p className="text-gray-500 italic">No appointments scheduled.</p>
    ) : (
      <ul className="space-y-4">
        {sortEventsByDate([...events]).map((event, index) => {
          const startTime = parseDate(event.start_time);
          const endTime = parseDate(event.end_time);

          if (!startTime || !endTime) {
            console.error(`Invalid date for event: ${event.event_id}`);
            return null;
          }

          const formattedDate = format(startTime, 'yyyy-MM-dd');
          const displayDate = format(startTime, 'MMMM do');
          const uniqueEventId = `${event.event_id}-${event.event_schedule_id}-${formattedDate}`;
          const eventStatus = getEventStatus(event);
          const eventClass = getEventClass(event);

          return (
            <li
              key={`${event.event_id}-${event.event_schedule_id}-${format(startTime, 'yyyy-MM-dd')}-${event.ev_status}`}
              className={`${eventClass} rounded-lg p-4 shadow transition-all duration-200 hover:shadow-md`}
            >
              <div className="flex justify-between items-center">
                <h3 className="font-semibold text-lg text-gray-800">{event.event_name} | {displayDate}</h3>
                <span className="text-sm text-gray-600">
                  {format(startTime, 'h:mm a')} - {format(endTime, 'h:mm a')}
                </span>
              </div>
              <div className="mt-2 flex items-center">
                <span className="text-sm text-gray-600 mr-2">Status:</span>
                <select 
                  key={`${uniqueEventId}-${eventStatus}`} 
                  className="form-select text-sm border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" 
                  onChange={(e) => handleStatusChange(e, uniqueEventId, startTime)} 
                  value={eventStatus}
                >
                  <option value="Confirmed">Confirmed</option>
                  <option value="Scheduled">Scheduled</option>
                  <option value="Canceled by Patient">Canceled by Patient</option>
                  <option value="Canceled by Provider">Canceled by Provider</option>
                  <option value="No Show">No Show</option>
                </select>
              </div>
            </li>
          );
        })}
      </ul>
    )}
  </div>
</div>
    </div>
  );
};

export default ScheduleTab;