// Tabs.js
import React, { useState } from 'react';
import OTPayInsuranceTab from './OTPayInsuranceTab';
import OTPayOopTab from './OTPayOopTab';

const OTInsuranceTable = () => {
  const [primary, setPrimary] = useState('payinsurance');
  const [activeTab, setActiveTab] = useState(() => {
    return primary === 'payinsurance' ? 'payinsurance' : 'payoop';
  });

  const [checkboxes, setCheckboxes] = useState({
    alertBilling: false,
    alertPC: false,
    reportedBenefits: false,
  });

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handlePrimaryMethodChange = (e) => {
    setPrimary(e.target.value);
  };

  const handleCheckboxChange = (event) => {
    setCheckboxes({
      ...checkboxes,
      [event.target.name]: event.target.checked,
    });
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'payinsurance':
        return <OTPayInsuranceTab />;
      case 'payoop':
        return <OTPayOopTab />;
      default:
        return null;
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md h-full">
      <h2 className="flex items-center ml-2 mb-2">
        Primary Billing Method for Occupational Therapy:
        <div className="ml-4">
          <label>
            <input
              type="radio"
              value="payinsurance"
              checked={primary === 'payinsurance'}
              onChange={handlePrimaryMethodChange}
              className="mr-2"
            />
            Via Insurance
          </label>
          <label className="ml-4">
            <input
              type="radio"
              value="payoop"
              checked={primary === 'payoop'}
              onChange={handlePrimaryMethodChange}
              className="mr-2"
            />
            Via OOP
          </label>
        </div>
        <div className="ml-4">
          <label>
            <input
              type="checkbox"
              className="mx-2"
              name="alertBilling"
              checked={checkboxes.alertBilling}
              onChange={handleCheckboxChange}
            />
            Alert Billing, Insurance Verification Needed
          </label>
          <label>
            <input
              type="checkbox"
              className="mx-2"
              name="alertPC"
              checked={checkboxes.alertPC}
              onChange={handleCheckboxChange}
            />
            Alert PC, Insurance Verification Complete
          </label>
          <label>
            <input
              type="checkbox"
              className="mx-2"
              name="reportedBenefits"
              checked={checkboxes.reportedBenefits}
              onChange={handleCheckboxChange}
            />
            Reported Benefits
          </label>
        </div>
      </h2>
      <div className="flex justify-center border-b border-gray-200">
        <button
          className={`px-4 py-2 font-medium text-sm ${
            activeTab === 'payinsurance'
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => handleTabClick('payinsurance')}
        >
          Via Insurance
        </button>
        <button
          className={`px-4 py-2 font-medium text-sm ${
            activeTab === 'payoop'
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500 hover:text-gray-700'
          }`}
          onClick={() => handleTabClick('payoop')}
        >
          Via OOP
        </button>
      </div>
      <div className="p-6">{renderTabContent()}</div>
    </div>
  );
};

export default OTInsuranceTable;